<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body" *ngIf="internEvents.length === 0 && !isLoading">
    <span class="content-no-message">Keine Events vorhanden</span>
  </div>

  <div class="dialog-form-body" *ngIf="isLoading">
    <span class="content-no-message">Events werden geladen...</span>
  </div>

  <div class="dialog-form-body" *ngIf="internEvents.length > 0 && !isLoading">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="event">Event</label>
        <p-dropdown
          inputId="event"
          name="event"
          appendTo="body"
          placeholder="Bitte auswählen"
          [options]="internEvents"
          [(ngModel)]="values.selected_event"
          [required]="true"
          (onChange)="dateTypeChanged()"
        >
          <ng-template let-event pTemplate="selectedItem">
            <div style="display: flex; align-items: center; gap: 10px">
              {{ event.full_event_name }}
              <span
                class="p-tag p-tag-rounded p-tag-outline"
                *ngIf="!event.read"
                >NEU</span
              >
            </div>
          </ng-template>
          <ng-template let-event pTemplate="item">
            <div style="display: flex; align-items: center; gap: 10px">
              {{ event.full_event_name }}
              <span
                class="p-tag p-tag-rounded p-tag-outline"
                *ngIf="!event.read"
                >NEU</span
              >
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="p-field p-col-12" *ngIf="values.selected_event">
        <label>Datum</label>
        <strong>{{ values.selected_event.date | date: "dd.MM.y" }}</strong>
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="
          values.selected_event && values.selected_event.time_type === 'FIXED'
        "
      >
        <label>Uhrzeit</label>
        <strong
          >{{ values.selected_event.from_time }} -
          {{ values.selected_event.to_time }} Uhr</strong
        >
      </div>

      <div class="p-field p-col-12" *ngIf="values.selected_event">
        <label>Referent</label>
        <strong *ngIf="!values.selected_event.is_extern_lecturer">
          {{ values.selected_event.lecturer.first_name }}
          {{ values.selected_event.lecturer.last_name }}
        </strong>
        <strong *ngIf="values.selected_event.is_extern_lecturer">
          {{ values.selected_event.extern_lecturer }}
        </strong>
      </div>

      <div class="p-field p-col-12" *ngIf="values.selected_event">
        <label>Adresse</label>
        <strong>{{ values.selected_event.address }}</strong>
      </div>

      <div class="p-field p-col-12" *ngIf="values.selected_event">
        <label>Bemerkung</label>
        <strong>{{ values.selected_event.comment || "-" }}</strong>
      </div>

      <ng-container
        *ngIf="
          values.selected_event && values.selected_event.time_type === 'SLOTS'
        "
      >
        <div class="p-field p-col-12">
          <label>Uhrzeit auswählen</label>
        </div>

        <div
          class="p-field p-col-12 p-sm-6"
          (click)="selectTime(time)"
          *ngFor="let time of values.selected_event.times"
        >
          <div
            class="time-slots-container selectable"
            [class.disabled]="
              (time.is_full && values.selected_event.time?.id !== time.id) ||
              !time.is_active
            "
            [class.selected]="values.selected_time?.id === time.id"
          >
            <div class="time-slot without-border">
              {{ time.from_time }} - {{ time.to_time }} Uhr

              <i class="pi pi-check" style="font-size: 20px"></i>
              <i
                class="pi pi-times color-alert"
                *ngIf="
                  (time.is_full &&
                    values.selected_event.time?.id !== time.id) ||
                  !time.is_active
                "
                style="font-size: 20px"
              ></i>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="detail-information-banner" *ngIf="alreadyFull">
      <span
        *ngIf="
          values.selected_event?.time_type === 'FIXED' &&
          !values.selected_event?.time
        "
        >Das Event ist leider bereits ausgebucht</span
      >
      <span *ngIf="values.selected_event?.time_type === 'SLOTS'"
        >Die Uhrzeit ist leider bereits ausgebucht</span
      >
    </div>

    <div
      class="detail-information-banner light-version"
      *ngIf="values.selected_event?.time"
    >
      <span>Sie sind bereits angemeldet für dieses Event</span>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!values.selected_event?.time"></i>

    <button
      *ngIf="values.selected_event?.time"
      (click)="removeFromBooking()"
      pButton
      label="Abmelden"
      type="button"
      class="p-button-outlined"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-times'"
    ></button>

    <button
      (click)="upload()"
      pButton
      label="Anmelden"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
