import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from '../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AuthService } from '../../services/auth.service'
import { AddInternEventDialogComponent } from '../../components/dialogs/add-intern-event-dialog/add-intern-event-dialog.component'
import { InternEventService } from '../../services/intern-event.service'
import { InternEventModel } from '../../models/intern-event/intern-event.model'

@Component({
  selector: 'app-intern-events',
  templateUrl: './intern-events.component.html',
})
export class InternEventsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public firstPage = 0

  private eventBusSubscription: Subscription = new Subscription()

  public onlyArchived = false

  public loading = false
  public internEvents: InternEventModel[] = []

  constructor(
    public authService: AuthService,
    private internEventService: InternEventService,
    private dialogService: DialogService,
    private router: Router,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadData()
    this.listenForEventbus()
    this.titleService.setTitle('Events')
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openAddInternEventDialog(event = null): void {
    this.dialogService.open(AddInternEventDialogComponent, {
      header: event ? 'Event bearbeiten' : 'Neues Event',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
      data: {
        event,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.InternEventChanged:
            this.loadData(false)
        }
      }
    )
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.internEventService.load().subscribe((data: InternEventModel[]) => {
      this.internEvents = data

      this.loading = false
    })
  }
}
