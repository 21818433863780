import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CaregiverDatesModel } from '../../../../../models/caregiver/caregiver-dates.model'
import { AddCaregiverDateDialogComponent } from '../../../../../components/dialogs/add-caregiver-date-dialog/add-caregiver-date-dialog.component'

@Component({
  selector: 'app-caregivers-detail-dates',
  templateUrl: './caregivers-detail-dates.component.html',
  providers: [DialogService],
})
export class CaregiversDetailDatesComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public dates: CaregiverDatesModel[] = []
  public caregiverId: string | null = null
  public globalFilterValue = ''

  private eventBusSubscription: Subscription = new Subscription()
  private routeSubscription: any = null

  constructor(
    public caregiverService: CaregiverService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadDates()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverDateChanged:
            this.loadDates(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openDateDialog(date: CaregiverDatesModel | null = null): void {
    if (date) {
      // Interne Events können hier nicht bearbeitet werden.
      if (
        date.type === 'intern-event-with-working-time' ||
        date.type === 'intern-event-without-working-time'
      ) {
        return
      }
    }

    this.dialogService.open(AddCaregiverDateDialogComponent, {
      header: date ? 'Abwesenheit bearbeiten' : 'Neue Abwesenheit',
      width: '490px',
      styleClass: 'dialog-container',
      data: {
        isEdit: date !== null,
        date,
        caregiver_id: this.caregiverId,
      },
    })
  }

  public loadDates(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent?.parent) {
      this.routeSubscription = this.route.parent.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.caregiverService
            .dates(this.caregiverId)
            .subscribe((data: CaregiverDatesModel[]) => {
              this.dates = data

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }
}
