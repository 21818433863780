import { UserModel } from './user.model'

export class UserSystemModel {
  id: number | null = null
  user_id: number | null = null
  form_of_address = ''
  first_name = ''
  last_name = ''
  full_name = ''
  birthday_date_string = ''
  birthday_date = ''
  phone_1 = ''
  phone_2 = ''
  signature = ''
  for_consulting = false
  as_supervisor = false
  for_lecturing = false
  for_thc = false
  user?: UserModel = new UserModel()
}

export class UserSystemSimpleModel {
  id = 0
  form_of_address = ''
  first_name = ''
  last_name = ''
  full_name = ''
}
