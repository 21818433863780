import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { InternEventModel } from '../models/intern-event/intern-event.model'

@Injectable({
  providedIn: 'root',
})
export class InternEventService {
  constructor(private http: HttpClient) {}

  public allEventsForCaregivers(): Observable<InternEventModel[]> {
    return this.http.get<InternEventModel[]>(
      `${environment.api}/intern-event/caregiver/events`
    )
  }

  public unreadEventsForCaregiver(): Observable<InternEventModel[]> {
    return this.http.get<InternEventModel[]>(
      `${environment.api}/intern-event/caregiver/unread-events`
    )
  }

  public load(): Observable<InternEventModel[]> {
    return this.http.get<InternEventModel[]>(`${environment.api}/intern-event`)
  }

  public loadAllEventsForMe(): Observable<InternEventModel[]> {
    return this.http.get<InternEventModel[]>(
      `${environment.api}/intern-event/my-events`
    )
  }

  public loadAllEventsForMeAsCaregiver(): Observable<InternEventModel[]> {
    return this.http.get<InternEventModel[]>(
      `${environment.api}/intern-event/caregiver/my-events`
    )
  }

  public store(data: InternEventModel): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/intern-event`, {
      ...data,
    })
  }

  public markAsReadForCaregiver(internEventModelId: number): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/intern-event/caregiver/read/${internEventModelId}`,
      {}
    )
  }

  public book(internEventId: number, selectedTimeId: number): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/intern-event/caregiver/book`,
      {
        internEventId,
        selectedTimeId,
      }
    )
  }

  public removeFromBooking(internEventId: number): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/intern-event/caregiver/book/${internEventId}`
    )
  }

  public remove(internEventId: number): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/intern-event/${internEventId}`
    )
  }

  public update(data: InternEventModel): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/intern-event/${data.id}`, {
      ...data,
    })
  }
}
