<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar white-sidebar space-between">
    <!-- Auswahl Adresse -->
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <span class="content-sub-headline">Adresse</span>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Büro"
              name="address_type"
              [(ngModel)]="internEvent.address_type"
              value="OFFICE"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Individuell"
              name="address_type"
              [(ngModel)]="internEvent.address_type"
              value="INDIVIDUAL"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Online"
              name="address_type"
              [(ngModel)]="internEvent.address_type"
              value="ONLINE"
              required
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="internEvent.address_type === 'INDIVIDUAL'"
      >
        <div class="p-field p-col-12">
          <label for="street">Straße *</label>
          <input
            id="street"
            name="street"
            [(ngModel)]="internEvent.address_street_house_number"
            type="text"
            pInputText
            required
            #address_street_house_number="ngModel"
            [class.p-invalid]="
              address_street_house_number.invalid &&
              (address_street_house_number.dirty ||
                address_street_house_number.touched)
            "
          />
        </div>
        <div class="p-field p-col-4">
          <label for="zipcode">PLZ *</label>
          <input
            (change)="loadCity()"
            id="zipcode"
            name="zipcode"
            [(ngModel)]="internEvent.address_zipcode"
            type="text"
            pInputText
            required
            #address_zipcode="ngModel"
            [class.p-invalid]="
              address_zipcode.invalid &&
              (address_zipcode.dirty || address_zipcode.touched)
            "
          />
        </div>
        <div class="p-field p-col-8">
          <label for="city">Ort *</label>
          <input
            id="city"
            name="city"
            [(ngModel)]="internEvent.address_city"
            type="text"
            pInputText
            required
            #address_city="ngModel"
            [class.p-invalid]="
              address_city.invalid &&
              (address_city.dirty || address_city.touched)
            "
          />
        </div>
      </div>
    </div>

    <!-- Teilnehmer -->
    <div *ngIf="isEdit" class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <span class="content-sub-headline">Teilnehmer</span>
        <span class="color-gray" *ngIf="!internEvent.all_caregiver_names"
          >Noch keine Teilnehmer vorhanden</span
        >

        <span
          style="white-space: pre-wrap"
          *ngIf="internEvent.all_caregiver_names"
          >{{ internEvent.all_caregiver_names }}</span
        >
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label *ngIf="isEdit">Typ</label>
        <strong *ngIf="isEdit">{{
          internEvent.time_type === "FIXED" ? "Feste Uhrzeit" : "Zeitslots"
        }}</strong>

        <div class="p-formgroup-inline" *ngIf="!isEdit">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Feste Uhrzeit"
              name="time_type"
              [(ngModel)]="internEvent.time_type"
              value="FIXED"
              (onClick)="timeTypeChanged()"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Zeitslots"
              name="time_type"
              [(ngModel)]="internEvent.time_type"
              (onClick)="timeTypeChanged()"
              value="SLOTS"
              required
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12">
        <label for="title">Titel *</label>
        <input
          id="title"
          name="title"
          type="text"
          [(ngModel)]="internEvent.title"
          pInputText
          [required]="true"
          #title="ngModel"
          [class.p-invalid]="title.invalid && (title.dirty || title.touched)"
        />
      </div>

      <div class="p-field p-col-12">
        <label
          for="lecturer"
          style="display: flex; justify-content: space-between"
        >
          Referent *

          <div class="p-formgroup-inline">
            <div class="p-field-checkbox" style="margin: 0 !important">
              <p-checkbox
                name="is_extern_lecturer"
                [binary]="true"
                label="Extern"
                [(ngModel)]="internEvent.is_extern_lecturer"
              ></p-checkbox>
            </div>
          </div>
        </label>

        <input
          *ngIf="internEvent.is_extern_lecturer"
          id="lecturer"
          name="lecturer"
          placeholder="Namen eintragen"
          type="text"
          [(ngModel)]="internEvent.extern_lecturer"
          pInputText
          [required]="true"
          #extern_lecturer="ngModel"
          [class.p-invalid]="
            extern_lecturer.invalid &&
            (extern_lecturer.dirty || extern_lecturer.touched)
          "
        />

        <p-dropdown
          *ngIf="!internEvent.is_extern_lecturer"
          name="lecturer"
          inputId="lecturer"
          placeholder="Bitte auswählen"
          [(ngModel)]="internEvent.lecturer"
          [options]="lecturers"
          [required]="true"
          #lecturer="ngModel"
          [class.p-invalid]="
            lecturer.invalid && (lecturer.dirty || lecturer.touched)
          "
          optionLabel="name"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12" *ngIf="internEvent.is_extern_lecturer">
        <label for="extern_lecturer_email">Externer Referent E-Mail</label>
        <input
          id="extern_lecturer_email"
          name="extern_lecturer_email"
          placeholder="Optional"
          type="text"
          [(ngModel)]="internEvent.extern_lecturer_email"
          pInputText
          email
          #extern_lecturer_email="ngModel"
        />

        <div
          *ngIf="
            extern_lecturer_email.invalid &&
            (extern_lecturer_email.dirty || extern_lecturer_email.touched)
          "
          class="p-invalid"
        >
          <div *ngIf="extern_lecturer_email.errors?.email">
            <small>Keine richtige E-Mail</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [rows]="2"
          [(ngModel)]="internEvent.comment"
          pInputTextarea
        ></textarea>
      </div>

      <div class="p-field p-col-12">
        <label for="date">Datum *</label>
        <p-calendar
          [disabled]="isEdit && internEvent.time_type === 'SLOTS'"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="date"
          name="date"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="internEvent.date"
          dataType="string"
          [showButtonBar]="true"
          [required]="true"
          [showIcon]="true"
          #date="ngModel"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-4">
        <label for="from_time">von *</label>
        <p-calendar
          [disabled]="isEdit && internEvent.time_type === 'SLOTS'"
          (onBlur)="checkForTimes($event, 'from_time'); createSlots()"
          [stepMinute]="15"
          inputId="from_time"
          name="from_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="internEvent.from_time"
          dataType="string"
          [showIcon]="true"
          icon="pi pi-clock"
          [required]="true"
          #from_time="ngModel"
          [showButtonBar]="false"
          [class.p-invalid]="
            from_time.invalid && (from_time.dirty || from_time.touched)
          "
        ></p-calendar>
      </div>

      <div class="p-field p-col-4">
        <label for="to_time">bis *</label>
        <p-calendar
          [disabled]="isEdit && internEvent.time_type === 'SLOTS'"
          (onBlur)="checkForTimes($event, 'to_time'); createSlots()"
          [stepMinute]="15"
          inputId="to_time"
          name="to_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="internEvent.to_time"
          dataType="string"
          [required]="true"
          [showIcon]="true"
          icon="pi pi-clock"
          #to_time="ngModel"
          [showButtonBar]="false"
          [class.p-invalid]="
            to_time.invalid && (to_time.dirty || to_time.touched)
          "
        ></p-calendar>
      </div>

      <div class="p-field p-col-4" *ngIf="internEvent.time_type === 'FIXED'">
        <label for="max_participants">Max. Teilnehmer</label>
        <div class="p-inputgroup">
          <p-inputNumber
            name="max_participants"
            [(ngModel)]="internEvent.times[0].max_participants"
            [showButtons]="false"
            inputId="max_participants"
            [min]="minParticipants"
            [allowEmpty]="true"
            [step]="1"
            [required]="true"
          >
          </p-inputNumber>
          <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
        </div>
      </div>

      <div class="p-field p-col-4" *ngIf="internEvent.time_type === 'SLOTS'">
        <label for="time_per_slot">Zeit pro Slot *</label>
        <p-dropdown
          (onChange)="createSlots()"
          name="time_per_slot"
          inputId="time_per_slot"
          placeholder="Bitte auswählen"
          [(ngModel)]="internEvent.time_per_slot"
          [options]="slotTimes"
          [disabled]="
            !internEvent.from_time ||
            !internEvent.to_time ||
            (isEdit && internEvent.time_type === 'SLOTS')
          "
          [pTooltip]="
            !internEvent.from_time || !internEvent.to_time
              ? 'Bitte Uhrzeit eintragen'
              : ''
          "
        ></p-dropdown>
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="
          internEvent.times.length > 0 &&
          internEvent.time_type === 'SLOTS' &&
          internEvent.from_time &&
          internEvent.to_time
        "
      >
        <p-table #dt [value]="internEvent.times" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>Aktiv</th>
              <th>Uhrzeit</th>
              <th style="text-align: right">Max. Teilnehmer</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-time>
            <tr>
              <td>
                <p-checkbox
                  [disabled]="time.bookings[0]?.caregiver"
                  label="Aktiv"
                  name="is_active-{{ time.id }}"
                  [binary]="true"
                  [(ngModel)]="time.is_active"
                ></p-checkbox>
              </td>
              <td>{{ time.from_time }} - {{ time.to_time }}</td>
              <td>
                <div style="width: 80px; margin-left: auto; display: flex">
                  <p-inputNumber
                    name="max_participants-{{ time.id }}"
                    [(ngModel)]="time.max_participants"
                    [showButtons]="false"
                    [min]="1"
                    [allowEmpty]="true"
                    [step]="1"
                    [required]="true"
                  >
                  </p-inputNumber>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="p-field p-col-12" *ngIf="slotIssue">
        <span class="color-alert"
          >Die Zeitslots passen nicht in die eingetragene Uhrzeit</span
        >
      </div>

      <div class="p-field p-col-12">
        <label for="is_working_time">Zählt als Arbeitszeit?</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="is_working_time"
              [(ngModel)]="internEvent.is_working_time"
              [value]="false"
              required
              #isWorkingTime="ngModel"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="is_working_time"
              [(ngModel)]="internEvent.is_working_time"
              [value]="true"
              required
              #isWorkingTime="ngModel"
            ></p-radioButton>
          </div>
        </div>
        <div
          *ngIf="
            isWorkingTime.invalid &&
            (isWorkingTime.dirty || isWorkingTime.touched)
          "
          class="p-invalid"
        >
          <div *ngIf="isWorkingTime.errors?.required">
            <small>Bitte wählen Sie etwas aus</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!isEdit"></i>

    <div style="display: flex; gap: 20px">
      <i
        class="pi pi-trash"
        *ngIf="isEdit"
        [class.pi-trash]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Event löschen'"
        (click)="remove($event)"
      ></i>
    </div>

    <button
      (click)="save()"
      pButton
      [label]="isEdit ? 'Speichern' : 'Speichern'"
      type="button"
      [disabled]="submitted || slotIssue"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
