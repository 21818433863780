import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { UserSystemService } from '../../../services/user-system.service'
import { SearchService } from '../../../services/search.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { VetoService } from '../../../services/veto.service'
import { PlanningService } from '../../../services/planning.service'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { ConfirmationService, SelectItem } from 'primeng/api'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { CaregiverService } from '../../../services/caregivers.service'
import { DiffViewDialogComponent } from '../diff-view-dialog/diff-view-dialog.component'
import { ConfirmWithTextDialogComponent } from '../confirm-with-text-dialog/confirm-with-text-dialog.component'
import { DesiredDateService } from '../../../services/desired-date.service'
import { DesiredDateModel } from '../../../models/desired-date/desired-date.model'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { AccountingReturnService } from '../../../services/accounting-return.service'
import { DocumentService } from '../../../services/document.service'
import { BroadcastChannel } from 'broadcast-channel'
import { TodoService } from '../../../services/todo.service'
import { TodoModel } from '../../../models/todo/todo.model'
import { ShowTodoDialogComponent } from '../show-todo-dialog/show-todo-dialog.component'
import { Clipboard } from '@angular/cdk/clipboard'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'

@Component({
  selector: 'app-caregiver-planning-dialog',
  templateUrl: './caregiver-planning-dialog.component.html',
})
export class CaregiverPlanningDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  public loading = true

  public veto: any
  public hasVeto = false

  public channel: any = null
  submitted = false
  submittedDelete = false

  public savedDataBeforeCheck: any = {}

  public selectedPatient: any = null
  public budgets: any[] = []
  public serviceScope = ''
  public weekHoursKostentraeger: any = 0
  public times: any = {}
  public userSystemOptions: any[] = []
  public caregivers: SearchResultModel[] = []
  public patients: SearchResultModel[] = []

  public keepAppointmentBefore = false

  public originalResponseAppointment: any = {}

  public hasInvoice = false
  public isEditMode = false
  public isCopyMode = false
  public checkingBudgets = false
  public activeTab = 'ACCOUNTING'
  public showPlausibleButton = false
  public errors = {
    holiday: false,
    time: false,
    time_split: false,
    time_quarter: [] as any[],
    plausible: true,
    plausibleMessage: '',

    plausibleData: [] as any,
  }

  public intervalOptions: SelectItem[] = [
    {
      label: 'Täglich',
      value: 'DAILY',
    },
    {
      label: 'Wöchentlich',
      value: 'WEEKLY',
    },
    {
      label: 'Zweiwöchentlich',
      value: 'BIWEEKLY',
    },
    {
      label: 'Monatlich',
      value: 'MONTHLY',
    },
  ]

  public intervalDaysOptions: SelectItem[] = [
    {
      label: 'Mo',
      value: 1,
    },
    {
      label: 'Di',
      value: 2,
    },
    {
      label: 'Mi',
      value: 3,
    },
    {
      label: 'Do',
      value: 4,
    },
    {
      label: 'Fr',
      value: 5,
    },
    {
      label: 'Sa',
      value: 6,
    },
  ]

  public accordions = {}

  public keys = Object.keys

  public nearPatientsLoading = false
  public area = 1
  public nearPatients = []
  public appointmentsForCaregiver: any[] = []

  public lastAppointments: {
    grouped: any
    appointments: any
  } = {
    grouped: {},
    appointments: {},
  }

  public patient: PatientModel = new PatientModel()

  public checkedIntervalAppointments: any = []
  public currentDay: any = null
  public currentWeek = ''
  public currentWeekIndex = 1
  public checkedTimeData: any = null
  public appointmentTask = ''

  public originalAppointment: any = {}

  public appointment: any = {
    date: '',
    date_to: '',
    comment: '',
    from: '',
    to: '',
    from_1: '',
    to_1: '',
    from_2: '',
    to_2: '',
    full_day_24h: false,
    doctor_appointment: false,
    shopping_appointment: false,
    not_changeable: false,
    keep_appointment: false,
    store_desired_date: false,
    not_changeable_reason: '',
    split_budget: false,
    budget_type: '',
    budget_type_2: '',
    caregiver_name: '',
    patient_id: '',
    caregiver_id: '',
    patient_name: '',
    with_beihilfe: false,
    with_interval: false,
    interval_type: 'WEEKLY',
    monthly_type: 'SAME_DAY',
    monthly_week: 1,
    interval_days: [],
  }

  public wasWithInterval = false
  public data: any
  public histories: any[] = []
  public holidays: any = {}
  public desiredDates: DesiredDateModel[] = []
  public accountings: any[] = []
  public todos: any[] = []
  public desiredDatesCalendar: any = {}
  public addressCopied = false

  constructor(
    private ref: DynamicDialogRef,
    private clipboard: Clipboard,
    private config: DynamicDialogConfig,
    private searchService: SearchService,
    private vetoService: VetoService,
    private caregiverService: CaregiverService,
    private accountingReturnService: AccountingReturnService,
    private todoService: TodoService,
    private userSystemService: UserSystemService,
    private dialogService: DialogService,
    private patientService: PatientService,
    private documentService: DocumentService,
    private eventbus: EventBusService,
    private planningService: PlanningService,
    private desiredDateService: DesiredDateService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  public ngOnInit(): void {
    this.data = this.config.data

    this.isEditMode = this.data.type === 'EDIT'
    this.isCopyMode = this.data.type === 'COPY'

    this.appointment.caregiver_id = this.data.caregiverId

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    if (this.isEditMode || this.isCopyMode) {
      this.loadAppointment()
    } else {
      this.appointment.date = this.data.newDate
      this.appointment.from = this.data.newTime
      this.loading = false
    }

    // this.checkedTimeData = this.data.date?.checked_time

    this.setCurrentDay()
    // this.openNearPatients()

    // this.loadDesiredDates()
    // this.loadLastAppointments()
    // this.loadHolidays()
    // this.loadHistoriesForAppointment()
    // this.loadCaregiverAppointments()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public budgetSplit(): void {
    if (this.appointment.split_budget) {
      this.appointment.from_1 = this.appointment.from
      this.appointment.to_2 = this.appointment.to
    }
  }

  public setRhytmus(): void {
    // Falls das tägliche Intervall ausgewählt wurde,
    // müssen alle Tage automatisch ausgewählt werden.
    if (this.appointment.interval_type === 'DAILY') {
      this.appointment.interval_days = this.intervalDaysOptions.map(
        (option: any) => option.value
      )
    }
  }

  public withIntervalToggle(): void {
    if (this.appointment.with_interval) {
      if (!this.appointment.date_to) {
        this.appointment.date_to = dayjs(this.appointment.date, 'DD.MM.YYYY')
          .endOf('month')
          .format('DD.MM.YYYY')
      }

      if (this.appointment.date) {
        if (this.appointment.interval_days.length < 2) {
          const dayIndex = dayjs(this.appointment.date, 'DD.MM.YYYY').format(
            'd'
          )
          this.appointment.interval_days = [+dayIndex]
        }
      }
    }
  }

  public setCurrentDay(): void {
    if (this.appointment.date) {
      this.currentDay = +dayjs(this.appointment.date, 'DD.MM.YYYY').format('D')
      this.currentWeek = dayjs(this.appointment.date, 'DD.MM.YYYY').format(
        'dddd'
      )

      this.currentWeekIndex = this.currentDay / 7
    }
  }

  public setCheckedTimeData(event: any): void {
    this.setCurrentDay()
    this.loadPatientBudgets()
  }

  public sub15Minutes(from: string, to: string): void {
    this.appointment[from] = dayjs('2000-01-01 ' + this.appointment[from])
      .subtract(15, 'minutes')
      .format('HH:mm')
    this.appointment[to] = dayjs('2000-01-01 ' + this.appointment[to])
      .subtract(15, 'minutes')
      .format('HH:mm')
  }

  public fullDay24hChanged(): void {
    if (this.appointment.full_day_24h) {
      this.appointment.to = '24:00'
    } else {
      this.appointment.to = ''
    }
  }

  public add15Minutes(from: string, to: string): void {
    // Falls keine "bis" Uhrzeit eingetragen ist (durch erstellung eines neuen Einsatzes),
    // wird die "von" Uhrzeit genommen und 45 min drauf addiert.
    if (!this.appointment[to]) {
      this.appointment[to] = dayjs('2000-01-01 ' + this.appointment[from])
        .add(45, 'minutes')
        .format('HH:mm')
    } else {
      this.appointment[from] = dayjs('2000-01-01 ' + this.appointment[from])
        .add(15, 'minutes')
        .format('HH:mm')
      this.appointment[to] = dayjs('2000-01-01 ' + this.appointment[to])
        .add(15, 'minutes')
        .format('HH:mm')
    }
  }

  public checkForTimes(event: any, type: string): void {
    const value = event.target.value

    if (value.length === 1) {
      this.appointment[type] = `0${value}:00`
    } else if (value.length === 2) {
      this.appointment[type] = `${value}:00`
    } else if (value.length === 4) {
      this.appointment[type] = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }

    if (type === 'from_2') {
      this.appointment.to_1 = this.appointment.from_2
    } else if (type === 'to_1') {
      this.appointment.from_2 = this.appointment.to_1
    }

    const time = this.appointment[type]

    if (time) {
      const minutes = time.split(':')[1]

      // Wir prüfen hier, ob die Minutenanzeige im 15-Minuten-Takt ist.
      if (!['00', '15', '30', '45'].includes(minutes)) {
        this.errors.time_quarter.push(type)
      } else {
        this.errors.time_quarter = this.errors.time_quarter.filter(
          (value: any) => value != type
        )
      }
    }
  }

  public appointmentForDate(date: any): string {
    const appointment = this.lastAppointments.appointments[
      date.day + '.' + (date.month + 1) + '.' + date.year
    ]

    if (appointment) {
      return `${appointment.real_from_h} - ${appointment.real_to_h} (${appointment.caregiver.first_last_name})`
    }

    return ''
  }

  public openNearPatients(): void {
    this.patientService
      .getNearPatients(this.data.patient.customer_id, this.area)
      .subscribe((patients: any) => {
        this.nearPatients = patients
      })
  }

  public changeTab(type: string): void {
    this.activeTab = type
  }

  public save(): void {
    if (!this.appointment.budget_type) {
      alert('Kein Budget ausgewählt')
      return
    }

    if (this.appointment.split_budget) {
      if (!this.appointment.budget_type_2) {
        alert('Bitte wählen Sie das zweite Budget')
        return
      }
    }

    if (this.appointment.split_budget) {
      if (this.appointment.budget_type_2 == this.appointment.budget_type) {
        alert('Das zweite Budget darf nicht gleich sein')
        return
      }
    }

    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (this.errors.time_quarter.length > 0) {
      return
    }

    this.showPlausibleButton = false
    this.errors.time = false
    this.errors.time_split = false
    this.errors.holiday = false
    this.errors.plausible = true
    this.errors.plausibleMessage = ''
    this.errors.plausibleData = []

    // Die Uhrzeiten werden als integer umgewandelt, dadurch
    // lässt es sich leicht nach größer/kleiner überprüfen.
    const integerStart = this.appointment.from
      ? +this.appointment.from.replace(':', '')
      : 0
    const integerEnd = this.appointment.to
      ? +this.appointment.to.replace(':', '')
      : 0

    if (integerStart >= integerEnd) {
      this.errors.time = true
      return
    }

    // Die Uhrzeiten bei der aufteilung müssen auch gecheckt werden.
    if (this.appointment.split_budget) {
      const integerStart1 = this.appointment.from_1
        ? +this.appointment.from_1.replace(':', '')
        : 0
      const integerEnd1 = this.appointment.to_1
        ? +this.appointment.to_1.replace(':', '')
        : 0

      const integerStart2 = this.appointment.from_2
        ? +this.appointment.from_2.replace(':', '')
        : 0
      const integerEnd2 = this.appointment.to_2
        ? +this.appointment.to_2.replace(':', '')
        : 0

      if (
        integerStart1 >= integerEnd1 ||
        integerStart2 >= integerEnd2 ||
        integerStart2 < integerEnd1
      ) {
        this.errors.time = true
        return
      }

      if (integerStart1 < integerStart || integerEnd2 > integerEnd) {
        this.errors.time_split = true
        return
      }

      if (integerEnd1 !== integerStart2) {
        this.errors.time_split = true
        return
      }
    }

    this.submitted = true

    // Wir speichern uns die genauen Daten vorher ab, damit wir prüfen können
    // ob der check für die Plausibilität nochmal gemacht werden
    // muss (weil Daten geändert wurden).
    this.savedDataBeforeCheck = JSON.stringify(this.appointment)

    // Im Kopiermodus dürfen wir die ID aus dem zu kopierenden Einsatz übergeben,
    // sonst berechnet er den Check für die Budgets falsch.
    const id = this.isCopyMode ? null : this.data.id

    // Es wird zuerst geprüft, ob das Datum und die Uhrzeit plausibel sind.
    // Das heißt, dass die BK in dem Zeitraum keinen Urlaub, AU, Planung, etc. hat
    // oder ob das Budget des Patienten ausgereizt ist.
    if (!this.appointment.split_budget) {
      // Falls keine Aufteilung vom Budget aktiviert ist, müssen wir den check nur 1x machen.
      this.planningService
        .checkForAppointmentPlausibility(this.appointment, id)
        .subscribe(
          (response: { plausible: boolean; message: string }[]) => {
            this.errors.plausibleData = response

            // Falls alles OK ist (keine Nachricht vorhanden ist),
            // wird der Termin erstellt/bearbeitet.
            if (response.length === 0) {
              this.saveAppointment()
            } else {
              // Wir prüfen, ob in der gesamten Plausibilität eins dabei ist,
              // das NICHT plausibel ist. Dann dürfen wir nicht speichern.
              const hasNotPlausibleData = response.find(
                (r: any) => !r.plausible
              )

              if (!hasNotPlausibleData) {
                this.showPlausibleButton = true
              }

              this.submitted = false
            }
          },
          () => {
            this.toastService.error(
              'Etwas ist schiefgelaufen...',
              'Bitte wenden Sie sich an den Support'
            )

            this.submitted = false
          }
        )
    } else {
      // Falls die Budgetaufteilung aktiv ist, müssen wir 2x den gleichen request machen.
      // Wir passen die Daten jeweils für den Request an. Das verhindert, dass wir
      // viele checks im Backend machen müssen.
      const newAppointment = { ...this.appointment }

      newAppointment.from = this.appointment.from_1
      newAppointment.to = this.appointment.to_1

      this.planningService
        .checkForAppointmentPlausibility(newAppointment, id)
        .subscribe(
          (response: { plausible: boolean; message: string }[]) => {
            this.errors.plausibleData = response

            newAppointment.from = this.appointment.from_2
            newAppointment.to = this.appointment.to_2
            newAppointment.budget_type = this.appointment.budget_type_2

            // Nun wird ein zweiter Request gestartet, um das zweite Budget zu prüfen.
            this.planningService
              .checkForAppointmentPlausibility(newAppointment, id)
              .subscribe(
                (response2: { plausible: boolean; message: string }[]) => {
                  // Die Fehlermeldungen werden dazuaddiert.
                  this.errors.plausibleData.push(...response2)

                  // Falls alles OK ist (keine Nachricht vorhanden ist),
                  // wird der Termin erstellt/bearbeitet.
                  if (response.length === 0) {
                    this.saveAppointment()
                  } else {
                    // Wir prüfen, ob in der gesamten Plausibilität eins dabei ist,
                    // das NICHT plausibel ist. Dann dürfen wir nicht speichern.
                    const hasNotPlausibleData = this.errors.plausibleData.find(
                      (r: any) => !r.plausible
                    )

                    if (!hasNotPlausibleData) {
                      this.showPlausibleButton = true
                    }

                    this.submitted = false
                  }
                },
                () => {
                  this.toastService.error(
                    'Etwas ist schiefgelaufen...',
                    'Bitte wenden Sie sich an den Support'
                  )

                  this.submitted = false
                }
              )
          },
          () => {
            this.toastService.error(
              'Etwas ist schiefgelaufen...',
              'Bitte wenden Sie sich an den Support'
            )

            this.submitted = false
          }
        )
    }
  }

  public formularHasChanged(): boolean {
    return JSON.stringify(this.appointment) !== this.savedDataBeforeCheck
  }

  public checkIntervalAppointments(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (this.errors.time_quarter.length > 0) {
      return
    }

    this.showPlausibleButton = false
    this.errors.time = false
    this.errors.time_split = false
    this.errors.holiday = false
    this.errors.plausible = true
    this.errors.plausibleMessage = ''
    this.errors.plausibleData = []

    // Die Uhrzeiten werden als integer umgewandelt, dadurch
    // lässt es sich leicht nach größer/kleiner überprüfen.
    const integerStart = this.appointment.from
      ? +this.appointment.from.replace(':', '')
      : 0
    const integerEnd = this.appointment.to
      ? +this.appointment.to.replace(':', '')
      : 0

    if (integerStart >= integerEnd) {
      this.errors.time = true
      return
    }

    this.submitted = true

    // Wir speichern uns die genauen Daten vorher ab, damit wir prüfen können
    // ob der check für die Plausibilität nochmal gemacht werden
    // muss (weil Daten geändert wurden).
    this.savedDataBeforeCheck = JSON.stringify(this.appointment)

    this.planningService.checkIntervalAppointments(this.appointment).subscribe(
      (appointments: any) => {
        this.checkedIntervalAppointments = appointments

        this.setPatientsTooltip()

        this.showPlausibleButton = true

        this.submitted = false
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public saveAppointment(): void {
    // Falls Daten im Formular geändert wurden, müssen wir "normal" speichern
    // damit der Check für die Plausibilität wieder ausgeführt wird.
    if (this.formularHasChanged()) {
      this.save()
      return
    }

    this.submitted = true

    const subscription = this.isEditMode
      ? this.planningService.changeAppointment(this.data.id, this.appointment)
      : this.planningService.addAppointment(this.appointment)

    subscription.subscribe(
      (response: any) => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.PlanningChanged)
        this.ref.close(response.persplan_budget_ids)

        this.channel = new BroadcastChannel('caregiver-appointment-changed')
        this.channel.postMessage({
          data: this.data,
        })

        const { caregiver_name, date, from, to } = this.appointment
        const message = `${caregiver_name} am ${date} ${from} - ${to}`

        if (this.isEditMode) {
          this.toastService.success('Einsatz verschoben', message)
        } else {
          this.toastService.success('Einsatz erstellt', message)
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.EXPECTATION_FAILED) {
          this.toastService.error(
            'Es wurde bereits eine Rechnung für diesen Einsatz gestellt'
          )
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public appointmentSelected(item: any): void {
    if (item.is_selected) {
      item.is_wish = false
    }

    this.checkBudgetsForIntervalAppointments()
  }

  private checkBudgetsForIntervalAppointments(): void {
    this.checkingBudgets = true

    this.planningService
      .checkBudgetsForIntervalAppointments(
        this.appointment,
        this.checkedIntervalAppointments
      )
      .subscribe(
        (data: any) => {
          this.checkedIntervalAppointments = data

          this.setPatientsTooltip()

          this.checkingBudgets = false
        },
        (error) => {
          this.checkingBudgets = false
        }
      )
  }

  public toggleWish(item: any): void {
    item.is_wish = !item.is_wish

    if (item.is_wish) {
      item.is_selected = false
    }

    this.checkBudgetsForIntervalAppointments()
  }

  public saveIntervalAppointments(): void {
    // Falls Daten im Formular geändert wurden, müssen wir "normal" speichern
    // damit der Check für die Plausibilität wieder ausgeführt wird.
    if (this.formularHasChanged()) {
      this.checkIntervalAppointments()
      return
    }

    // Es muss mindestens ein Termin angehakt sein.
    const hasCheckedAppointment = this.checkedIntervalAppointments.find(
      (appointment: any) => {
        return appointment.is_selected
      }
    )

    // Oder ein Wunschtermin ausgewählt sein.
    const hasWishAppointment = this.checkedIntervalAppointments.find(
      (appointment: any) => {
        return appointment.is_wish
      }
    )

    if (!hasCheckedAppointment && !hasWishAppointment) {
      alert('Bitte wählen Sie mindestens einen Termin oder Wunschtermin aus')
      return
    }

    this.submitted = true

    this.planningService
      .addIntervalAppointments(
        this.appointment,
        this.checkedIntervalAppointments
      )
      .subscribe(
        (response: any) => {
          this.submitted = false

          // Falls der nachträgliche Check neue Plausibilitätsfehler gefunden hat.
          if (response.errors && response.errors.length) {
            this.toastService.error(
              'Neue Fehler gefunden',
              'Bitte prüfen Sie nochmal alle Serientermine'
            )

            this.checkedIntervalAppointments = response.errors

            this.setPatientsTooltip()
          } else {
            this.toastService.success('Termine erstellt', '')
            this.eventbus.emit(GlobalEvent.PlanningChanged)
            this.ref.close(response.ids)
          }
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  private setPatientsTooltip(): void {
    this.checkedIntervalAppointments.forEach((data: any) => {
      let tooltip = ''

      if (data.patients.length) {
        tooltip += "<div style='display: flex; flex-direction: column'>"

        data.patients.forEach((patient: any, index: number) => {
          tooltip += "<small style='display: flex; flex-direction: column;'>"

          if (index === 0) {
            tooltip += '<span>Von Zuhause</span>'
          }

          tooltip +=
            '<small class="drive-time-info-in-list color-white">' +
            '<i style="font-size: 12px;" class="pi pi-car color-gray"></i>' +
            patient.from_patient_duration_seconds_rounded / 60 +
            'min' +
            '</small>'

          tooltip +=
            patient.patient.first_name +
            ' ' +
            patient.patient.last_name +
            ' ' +
            patient.real_from_h +
            ' - ' +
            patient.real_to_h

          tooltip += '</small>'
        })

        tooltip += '</div>'
      }

      data.tooltip_patients = tooltip
    })
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public patientSelected(): void {
    this.patient = this.selectedPatient

    this.appointment.patient_id = this.selectedPatient.id

    this.loadPatientBudgets()
  }

  public loadAppointment(): void {
    this.planningService
      .loadAppointment(this.data.id)
      .subscribe((response: any) => {
        this.originalResponseAppointment = response
        this.patient = response.patient

        this.hasInvoice = response.invoice_id !== null

        this.appointment.patient_id = response.patient_id
        this.appointment.patient_name = response.patient.full_name
        this.appointment.from = response.real_from_h
        this.appointment.to = response.real_to_h
        this.appointment.comment = response.task
        this.appointment.with_beihilfe = response.with_beihilfe
        this.appointment.doctor_appointment = response.doctor_appointment
        this.appointment.shopping_appointment = response.shopping_appointment
        this.appointment.change_required = response.change_required
        this.appointment.keep_appointment = response.keep_appointment
        this.keepAppointmentBefore = response.keep_appointment
        this.appointment.budget_type = response.persplan_function_formatted

        // Wir prüfen, ob die "to" Uhrzeit auf "24:00" steht.
        // Falls nicht, wurde dieser Termin durch eine splittung erstellt, und
        // nur der zweite Termin aus der splittung hat die "24:00" Uhr.
        this.appointment.full_day_24h = response.real_to_h === '24:00'

        if (this.isCopyMode) {
          this.appointment.date = this.data.newDate
          this.appointment.comment = ''
        }

        if (this.isEditMode) {
          this.wasWithInterval = response.with_interval
          this.appointment.date = dayjs(response.real_from).format('DD.MM.YYYY')

          if (this.data.newDate) {
            this.appointment.date = this.data.newDate
          }
        }

        if (this.isCopyMode || this.isEditMode) {
          if (this.data.newTime) {
            const from = dayjs('2000-01-01 ' + this.appointment.from)
            const to = dayjs('2000-01-01 ' + this.appointment.to)
            const newTime = dayjs('2000-01-01 ' + this.data.newTime)

            const currentDiff = to.diff(from, 'minutes')

            this.appointment.from = this.data.newTime
            this.appointment.to = newTime
              .add(currentDiff, 'minutes')
              .format('HH:mm')
          }

          // Wir speichern uns den originalen Termin ab, weil bei der
          // Stornierung geprüft werden muss, ob es eine Änderung gab.
          this.originalAppointment = JSON.parse(
            JSON.stringify(this.appointment)
          )
        }

        this.loadPatientBudgets()
      })
  }

  public copyAddressToClipboard(): void {
    this.clipboard.copy(this.patient.full_address)

    this.addressCopied = true

    setTimeout(() => {
      this.addressCopied = false
    }, 2000)
  }

  public openMediaFromUuid(uuid: string): void {
    window.open(this.documentService.getDocumentDownloadLink(uuid))
  }

  public loadAccountings(): void {
    if (this.patient) {
      this.accountingReturnService
        .loadForPatientNotFinished(this.patient.id)
        .subscribe((response: any[]) => {
          this.accountings = response

          this.loadTodos()
          this.loadDesiredDates()

          this.loading = false
        })
    }
  }

  public loadVetos(): void {
    if (this.patient) {
      this.patientService
        .loadVetoForCaregiver(this.patient.id, this.appointment.caregiver_id)
        .subscribe((response: any) => {
          this.veto = response
          this.hasVeto = response.veto || response.criteria
        })
    }
  }

  public openShowTodoDialog(todo: TodoModel): void {
    const ref = this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${todo?.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
        user_type_name: todo?.patient?.full_name,
      },
    })

    ref.onClose.subscribe(() => {
      this.loadTodos()
    })
  }

  public loadTodos(): void {
    if (this.patient) {
      this.todoService
        .openForPatient(this.patient.id)
        .subscribe((response: any[]) => {
          this.todos = response
        })
    }
  }

  public loadLastAppointments(): void {
    if (this.appointment.date) {
      this.planningService
        .loadLastAppointmentsForPatient(
          this.appointment.patient_id,
          this.appointment.date
        )
        .subscribe((response: any) => {
          this.lastAppointments = response
        })
    }
  }

  public loadPatientBudgets(): void {
    this.planningService
      .loadPatientBudgets(this.appointment.patient_id, this.appointment.date)
      .subscribe((response: any) => {
        this.appointmentTask = response.appointment_comment

        this.serviceScope = response.service_scope
        this.times = response.times
        this.budgets = response.budgets
        this.weekHoursKostentraeger = response.week_hours_kostentraeger

        // Ich muss prüfen, ob der Patient das bereits ausgewählte Budget überhaupt hat.
        // Das ausgewählte Budget könnte aus dem kopierten/verschobenen Termin sein,
        // und das Budget könnte aber im aktuellen Monat gar nicht vorhanden sein.
        if (this.appointment.budget_type) {
          const hasBudget = this.budgets.find((budget: any) => {
            return budget.type == this.appointment.budget_type
          })

          if (!hasBudget) {
            this.appointment.budget_type = ''
          }
        }

        this.loadAccountings()
        this.loadVetos()
      })
  }

  public keepChanged(): void {
    if (this.appointment.keep_appointment) {
      this.appointment.not_changeable = false
    }
  }

  public notChangeableChanged(): void {
    if (this.appointment.not_changeable) {
      this.appointment.keep_appointment = false
    } else {
      this.appointment.store_desired_date = false
    }
  }

  public loadDesiredDates(): void {
    this.desiredDateService
      .loadForPatient(this.patient.id)
      .subscribe((response: any) => {
        this.desiredDates = response.normal
      })
  }

  public loadHistoriesForAppointment(): void {
    // History nur laden, wenn es ein vorhander Termin ist.
    if (this.data?.data?.id) {
      this.planningService
        .loadAppointmentHistories(this.data.data.id)
        .subscribe((response: any[]) => {
          this.histories = response
        })
    }
  }

  public loadHolidays(): void {
    this.planningService.loadAllHolidays().subscribe((response: any[]) => {
      this.holidays = response
    })
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public getSelectedDay(type: 'date' | 'date_to'): string {
    if (this.appointment.date) {
      return dayjs(this.appointment[type], 'DD.MM.YYYY').format('dd')
    }

    return ''
  }

  public getCaregiverTimeTable(): string {
    const caregiver = this.data.caregivers.find(
      (c: any) => c.caregiver_id === this.appointment.caregiver_id
    )

    if (caregiver) {
      return caregiver.current_time.days_as_html_table
    }

    return ''
  }

  public remove(): void {
    // Stornierung lässt sich nicht öffnen, falls Änderungen im Einsatz gemacht wurden.
    // Weil wir beim Stornieren auf die richtigen Uhrzeiten angewiesen sind für die
    // Berechnungen der maximalen Werte (Fahrzeiten und anpassung der Uhrzeiten).
    if (
      this.originalAppointment.from !== this.appointment.from ||
      this.originalAppointment.to !== this.appointment.to ||
      this.originalAppointment.date !== this.appointment.date ||
      this.originalAppointment.budget_type !== this.appointment.budget_type
    ) {
      alert(
        'Änderungen bei Datum, Uhrzeit oder Budget erkannt. Bitte vorher speichern.'
      )

      return
    }

    const ref = this.dialogService.open(ConfirmWithTextDialogComponent, {
      header: 'Einsatz stornieren',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        appointment_complete: this.originalResponseAppointment,
        wasWithInterval: this.wasWithInterval,
        appointment: this.appointment,
      },
    })

    ref.onClose.subscribe((values: any) => {
      if (values) {
        this.submitted = true
        this.submittedDelete = true

        this.planningService.deleteAppointment(this.data.id, values).subscribe(
          (response: any) => {
            this.eventbus.emit(GlobalEvent.PlanningChanged)
            this.ref.close(true)

            this.toastService.success(
              'Einsatz storniert',
              'Der Einsatz wurde erfolgreich storniert'
            )
          },
          () => {
            this.toastService.error(
              'Etwas ist schiefgelaufen...',
              'Bitte wenden Sie sich an den Support'
            )

            this.submitted = false
            this.submittedDelete = false
          }
        )
      }
    })
  }
}
