<form
  #form="ngForm"
  class="dialog-form"
  style="height: 100%; display: flex; flex-direction: column"
>
  <div class="dialog-loader white-background" *ngIf="loading">
    <i class="loading-logo"></i>
    WIRD GELADEN
  </div>

  <div class="dialog-form-footer on-top" *ngIf="!loading && mediaGenerated">
    <i></i>
    <a target="_blank" [href]="generatedMediaLink">
      <button
        pButton
        label="Dokument herunterladen"
        type="button"
        icon="pi pi-download"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>
  </div>

  <div class="dialog-form-footer on-top" *ngIf="!loading && !mediaGenerated">
    <i></i>

    <div style="display: flex; gap: 10px">
      <button
        pButton
        label="Stornieren"
        class="p-button-danger"
        type="button"
        (click)="stornoInvoice()"
      ></button>

      <button
        (click)="activateSidebar()"
        pButton
        class="p-button-outlined"
        label="Nachbearbeitung"
        icon="pi pi-exclamation-triangle"
      ></button>

      <button
        pButton
        *ngIf="activeTab === 'CHECK'"
        [label]="
          dataForCheckStatusIsValid
            ? 'Prüfung speichern und weiter'
            : 'Prüfung speichern'
        "
        type="submit"
        tooltipStyleClass="tooltip-wider"
        (click)="saveCheckedStatus()"
        [disabled]="
          submitted ||
          hasOpenedReworks ||
          (!hasMedia && !data.proof_status.without_proof)
        "
        [pTooltip]="
          hasOpenedReworks
            ? 'Nachbearbeiten sind noch offen'
            : !hasMedia && !data.proof_status.without_proof
            ? 'Leistungsnachweis fehlt'
            : ''
        "
        [iconPos]="dataForCheckStatusIsValid ? 'right' : 'left'"
        [icon]="
          submitted
            ? 'pi pi-spin pi-spinner'
            : dataForCheckStatusIsValid
            ? 'pi pi-arrow-right'
            : 'pi pi-check'
        "
      ></button>

      <button
        pButton
        *ngIf="activeTab === 'BUDGET'"
        label="Budget speichern und weiter"
        type="submit"
        tooltipStyleClass="tooltip-wider"
        (click)="saveBudgetStatus()"
        [pTooltip]="
          hasOpenedReworks
            ? 'Nachbearbeiten sind noch offen'
            : dataForBudgetStatusIsValid
            ? ''
            : 'Häkchen fehlt oder Budget ist im Minus'
        "
        [disabled]="
          submitted || !dataForBudgetStatusIsValid || hasOpenedReworks
        "
        iconPos="right"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-arrow-right'"
      ></button>

      <a
        *ngIf="activeTab === 'INVOICE' && invoiceValues.send_type === 'email'"
        target="_blank"
        (click)="clickedOnPreviewMail = true"
        [href]="mailPreviewSrc"
      >
        <button
          pButton
          label="Mail Vorschau"
          type="button"
          [pTooltip]="isMailPreviewDisabled() ? 'Keine E-Mail ausgewählt' : ''"
          [disabled]="isMailPreviewDisabled()"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a
        *ngIf="activeTab === 'INVOICE'"
        target="_blank"
        (click)="clickedOnPreviewPdf = true"
        [href]="pdfPreviewSrc"
      >
        <button
          pButton
          label="PDF Vorschau"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a
        *ngIf="activeTab === 'INVOICE' && data.proof_status.proof_with_stamp"
        target="_blank"
        (click)="clickedOnPreviewLnw = true"
        [href]="imgSrcLnw"
      >
        <button
          pButton
          [disabled]="!data.proof_status.proof_with_stamp"
          [pTooltip]="
            !data.proof_status.proof_with_stamp
              ? 'Kein LNW mit Stempel vorhanden'
              : ''
          "
          label="LNW Vorschau"
          tooltipStyleClass="tooltip-wider"
          tooltipPosition="left"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <p-splitButton
        *ngIf="activeTab === 'INVOICE'"
        label="Entwurf speichern"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        [model]="buttonItems"
        (onClick)="saveInvoiceStatus()"
        appendTo="body"
        tooltipStyleClass="tooltip-wider"
        [disabled]="submitted || hasOpenedReworks"
        [pTooltip]="hasOpenedReworks ? 'Nachbearbeiten sind noch offen' : ''"
      >
      </p-splitButton>
    </div>
  </div>

  <div
    class="dialog-form-body without-padding"
    style="height: calc(100% - 54px)"
    *ngIf="!loading"
  >
    <p-sidebar [modal]="false" [(visible)]="showSidebar" position="left">
      <h2>NACHBEARBEITUNG</h2>
      <div style="display: flex; padding: 20px; flex-direction: column">
        <div class="p-fluid p-formgrid p-grid without-border">
          <div class="p-field p-col-12">
            <label for="type">Typ *</label>
            <p-multiSelect
              inputId="type"
              placeholder="Bitte wählen"
              [options]="typeOptions"
              [filter]="false"
              [showHeader]="false"
              name="type"
              appendTo="body"
              scrollHeight="400px"
              [(ngModel)]="type"
            ></p-multiSelect>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid without-border">
          <div class="p-field p-col-12" style="margin-top: -15px">
            <label for="information">Informationen *</label>
            <textarea
              id="information"
              name="information"
              [rows]="4"
              pInputTextarea
              [(ngModel)]="comment"
            ></textarea>
          </div>
          <div class="p-field p-col-12">
            <button
              pButton
              (click)="addProofRework()"
              label="Eintragen"
              class="p-button-outlined"
              [disabled]="!comment || submitted"
              type="button"
            ></button>
          </div>
        </div>
      </div>

      <div class="dialog-sidebar-comments-container">
        <div
          class="dialog-no-message dialog-no-comment"
          style="padding: 20px"
          *ngIf="data.proof_status.reworkings.length === 0"
        >
          Keine Nachbearbeitung vorhanden
        </div>
        <div
          class="dialog-sidebar-comment"
          style="padding: 10px 20px"
          *ngFor="let rework of data.proof_status.reworkings"
        >
          <strong>{{ rework.reason }}</strong>
          <span>Typ: {{ rework.types_string }}</span>
          <span
            >Erstellt von {{ rework.created_from_user.name }} am
            {{ rework.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
          >
          <span
            style="display: flex; align-items: center; gap: 5px"
            *ngIf="rework.finished_from"
            >Erledigt von {{ rework.finished_from_user.name }} am
            {{ rework.finished_at | date: "dd.MM.y, H:mm" }} Uhr
            <i
              class="pi pi-info-circle"
              [pTooltip]="rework.finished_comment"
            ></i
          ></span>
        </div>
      </div>
    </p-sidebar>

    <div class="proof-check-container">
      <!-- Links -->
      <div class="proof-check-image-container">
        <div class="proof-check-buttons">
          <div
            *ngIf="
              data.proof_status.status === 'INVOICE' || data.proofs.length > 1
            "
          >
            <button
              *ngIf="data.proof_status.status === 'INVOICE'"
              (click)="changeGlobalTab('INVOICE')"
              pButton
              label="Rechnungsentwurf"
              class="p-button-dark"
              [class.active]="selectedGlobalTab.type === 'INVOICE'"
            ></button>

            <button
              *ngFor="let proof of data.proofs; index as i"
              (click)="changeGlobalTab('PROOF', i)"
              pButton
              [class.pointer-events-none]="viewerLoading"
              class="p-button-dark"
              label="Leistungsnachweis {{
                data.proofs.length > 1 ? i + 1 : ''
              }}"
              [class.active]="
                selectedGlobalTab.type === 'PROOF' &&
                selectedGlobalTab.index == i
              "
            ></button>

            <div class="with-multiple-buttons" *ngIf="data.proofs.length === 1">
              <button
                (click)="changeGlobalTab('STAMP')"
                pButton
                label="Stempel"
                class="p-button-dark"
                [class.active]="selectedGlobalTab.type === 'STAMP'"
              ></button>

              <div
                class="proof-check-dropdown-buttons"
                *ngIf="selectedGlobalTab.type === 'STAMP'"
              >
                <button
                  (click)="toggleWithDateStamp()"
                  pButton
                  label="mit Datum"
                  class="p-button-dark"
                  [class.active]="withDateStamp"
                ></button>

                <button
                  (click)="mergeImages()"
                  pButton
                  [icon]="
                    stampSaveSubmitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'
                  "
                  iconPos="left"
                  [disabled]="stampSaveSubmitted"
                  label="Speichern"
                  class="p-button-dark"
                ></button>
              </div>
            </div>
          </div>

          <i *ngIf="data.proof_status.status !== 'INVOICE'"></i>
          <div>
            <button
              *ngIf="data.proofs.length <= 1"
              pButton
              class="p-button-dark"
              icon="pi pi-upload"
              iconPos="left"
              (click)="openUploadSingleProofDialog()"
              label="Neuen LNW hochladen"
            ></button>

            <button
              *ngIf="
                data.proofs.length > 1 && selectedGlobalTab.type === 'PROOF'
              "
              pButton
              class="p-button-dark"
              icon="pi pi-times"
              iconPos="left"
              (click)="removeProof()"
              label="LNW löschen"
            ></button>
          </div>
        </div>

        <div
          *ngIf="!hasMedia && selectedGlobalTab.type === 'PROOF'"
          class="proof-check-no-image-container"
        >
          Kein Leistungsnachweis hochgeladen
        </div>

        <div
          *ngIf="selectedGlobalTab.type === 'STAMP'"
          class="proof-check-stamp-container"
          id="proof-check-stamp-container"
        >
          <img
            *ngIf="!withDateStamp"
            class="draggable"
            id="image-stamp"
            width="200"
            height="auto"
            src="/assets/img/stempel-1.png"
          />

          <img
            *ngIf="withDateStamp"
            class="draggable"
            id="image-stamp"
            width="200"
            height="auto"
            src="/assets/img/stempel-15.png"
          />

          <img id="image-lnw-for-stamp" [src]="imgSrc" />
        </div>

        <div
          [class.is-rotating]="rotating"
          class="proof-check-image-item"
          *ngIf="selectedGlobalTab.type === 'PROOF'"
        >
          <img id="image" style="display: none" [src]="imgSrc" />
        </div>

        <!-- Dummy Vorschau -->
        <div
          class="proof-check-image-item"
          *ngIf="selectedGlobalTab.type === 'INVOICE'"
        >
          <div class="proof-check-invoice-wrapper" style="padding: 0">
            <embed
              [src]="pdfPreviewSrcSafe"
              type="application/pdf"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>

      <!-- Rechts -->
      <div class="proof-check-actions-container">
        <div class="dialog-sidebar-tabs">
          <span
            [class.active]="activeTab === 'CHECK'"
            (click)="changeTab('CHECK')"
            >Prüfung</span
          >
          <i class="pi pi-arrow-right"></i>
          <span
            [class.disabled]="data.proof_status.status === 'CHECK'"
            [class.active]="activeTab === 'BUDGET'"
            (click)="changeTab('BUDGET')"
            >Budget</span
          >
          <i class="pi pi-arrow-right"></i>
          <span
            [class.disabled]="
              data.proof_status.status === 'CHECK' ||
              data.proof_status.status === 'BUDGET'
            "
            [class.active]="activeTab === 'INVOICE'"
            (click)="changeTab('INVOICE')"
            >Rechnung</span
          >
        </div>

        <div
          *ngIf="previousInvoice"
          class="detail-information-banner"
          style="margin: 0; padding: 3px 20px; font-size: 14px"
        >
          <a
            style="display: flex; align-items: center; gap: 5px"
            [href]="
              documentService.getDocumentDownloadLink(
                previousInvoice.media[0].uuid
              )
            "
            target="_blank"
          >
            Rechnung aus Stornierung ({{
              previousInvoice.invoice_number_complete
            }})
            <i style="font-size: 12px" class="pi pi-arrow-right"></i>
          </a>
        </div>

        <div
          style="gap: 1px; flex-direction: column; display: flex"
          *ngIf="hasOpenedReworks"
        >
          <div
            *ngFor="let rework of data.proof_status.reworkings"
            class="detail-information-banner warning-version"
            style="
              align-items: center;
              margin: 0;
              justify-content: space-between;
              flex-direction: row;
            "
          >
            <ng-container *ngIf="rework.status === 'OPENED'">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <span>{{ rework.reason }}</span>
                <small>Erstellt von {{ rework.created_from_user.name }}</small>
              </div>

              <button
                style="margin: 0 20px 0 0"
                pButton
                (click)="openFinishReworkDialog(rework)"
                label="Erledigen"
                class="p-button-outlined background-white"
                type="button"
              ></button>
            </ng-container>
          </div>
        </div>

        <!-- Patient Daten -->
        <div
          class="sidebar-inline-calendar with-border"
          style="padding: 20px; gap: 30px"
        >
          <div
            style="display: flex; flex-direction: column; white-space: nowrap"
          >
            <label>Patient</label>
            <a
              target="_blank"
              style="color: currentColor"
              routerLink="/patients/{{ data.patient_id }}"
            >
              <strong
                >{{ data.patient_first_name }}
                {{ data.patient_last_name }}</strong
              ><i
                style="font-size: 12px; margin: 0 0 0 5px"
                class="color-gray pi pi-arrow-up-right"
              ></i>
            </a>
          </div>

          <div style="display: flex; flex-direction: column">
            <label>Zeitraum</label>
            <strong
              >{{ data.proof_status.month }}
              {{ data.proof_status.year }}</strong
            >
          </div>

          <div style="display: flex; flex-direction: column">
            <label>Abrechnungsstatus</label>
            <strong
              >{{ data.patient_insured_via_string || "-" }}
              {{
                data.patient_insured_via_other_text
                  ? "(" + data.patient_insured_via_other_text + ")"
                  : ""
              }}</strong
            >
            <strong
              class="color-gray"
              *ngIf="
                data.patient_insured_via.includes('Selbstzahler') &&
                data.patient_selbstzahler_budget_type
              "
            >
              Selbstzahler
              {{ data.patient_selbstzahler_budget_type }}
              {{
                data.patient_selbstzahler_budget_type === "Max"
                  ? data.patient_selbstzahler_budget_max + " Std"
                  : ""
              }}</strong
            >
          </div>

          <div style="display: flex; flex-direction: column" *ngIf="hasMedia">
            <label>Deckblatt</label>
            <strong>{{ data.proof_status?.cover_key || "UNBEKANNT" }}</strong>
          </div>
        </div>

        <!-- Patient Daten 2 -->
        <div
          class="sidebar-inline-calendar with-border"
          *ngIf="receiversData.customer && activeTab === 'INVOICE'"
          style="padding: 20px; gap: 30px"
        >
          <div style="display: flex; flex-direction: column">
            <label>Notiz Buchhaltung</label>

            <strong
              *ngIf="receiversData.customer.notice_accounting"
              style="display: flex; align-items: center; gap: 10px"
              >Eintrag
              <i
                class="pi pi-info-circle"
                [pTooltip]="receiversData.customer.notice_accounting"
              ></i
            ></strong>

            <strong *ngIf="!receiversData.customer.notice_accounting"
              >Keine Angabe</strong
            >
          </div>

          <div style="display: flex; flex-direction: column">
            <label>Rechnungsversand</label>
            <strong style="display: flex; align-items: center; gap: 10px">
              {{ currentPatient.invoice_types_string || "Keine Angabe" }}
              <i
                class="pi pi-info-circle"
                [pTooltip]="currentPatient.invoice_type_tooltip"
                *ngIf="currentPatient.invoice_type_tooltip"
              ></i>
            </strong>
          </div>

          <div style="display: flex; flex-direction: column">
            <label>AP für Rechnung</label>
            <strong style="display: flex; align-items: center; gap: 10px">
              {{ contactPersonsForInvoice || "Keine Angabe" }}
            </strong>
          </div>

          <div style="display: flex; flex-direction: column">
            <label>SEPA</label>
            <strong style="display: flex; align-items: center; gap: 5px">
              {{ data.patient_first_sepa ? "Ja" : "Nein" }}
            </strong>
          </div>
        </div>

        <!-- CHECK -->
        <div *ngIf="activeTab === 'CHECK'" class="sidebar-inline-calendar">
          <p-table [value]="data.persplan_budgets.appointments">
            <ng-template pTemplate="header">
              <tr>
                <th>Prüfung</th>
                <th>Betreuungskraft</th>
                <th>Datum</th>
                <th>Uhrzeit</th>
                <th>Dauer</th>
                <th>Leistungsart</th>
                <th>Freigabe</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
              <tr [attr.data-id]="item.id">
                <td>
                  <p-checkbox
                    label="Geprüft"
                    [name]="'checked-' + rowIndex"
                    [binary]="true"
                    [(ngModel)]="item.checked_proof"
                    (onChange)="dataChangedForCheckedStatus()"
                  ></p-checkbox>
                </td>
                <td>
                  <div style="display: flex; align-items: center; gap: 5px">
                    <a
                      target="_blank"
                      class="color-main1"
                      routerLink="/caregivers/{{ item.caregiver_id }}/planning"
                      >{{ item.caregiver_name }}</a
                    >
                    <i
                      class="pi pi-flag-fill color-gray"
                      *ngIf="item.keep_appointment"
                      pTooltip="Termin beibehalten"
                      style="font-size: 13px"
                    ></i>

                    <i
                      class="pi pi-flag-fill color-orange"
                      *ngIf="item.calculate_appointment"
                      pTooltip="Termin storniert"
                      style="font-size: 13px"
                    ></i>
                  </div>
                </td>
                <td>
                  <div style="display: flex; align-items: center; gap: 5px">
                    {{ item.date }}
                    <i
                      pTooltip="Bearbeiten"
                      class="pi pi-pencil"
                      style="display: block"
                      (click)="openCaregiverPlanningDialog(item)"
                    ></i>
                  </div>
                </td>
                <td>
                  <div style="display: flex; align-items: center; gap: 5px">
                    {{ item.from_h }} - {{ item.to_h }}
                    <i
                      pTooltip="Bearbeiten"
                      class="pi pi-pencil"
                      style="display: block"
                      (click)="openCaregiverPlanningDialog(item)"
                    ></i>
                  </div>
                </td>
                <td>
                  <div style="display: flex; align-items: center; gap: 5px">
                    {{ item.hours }} Std
                  </div>
                </td>
                <td>
                  {{ item.persplan_function_complete }}
                </td>
                <td>
                  <div style="display: flex; align-items: center; gap: 10px">
                    <i
                      *ngIf="item.released_id && !item.from_admin"
                      class="pi pi-check-circle color-main1"
                      tooltipStyleClass="tooltip-wider"
                      tooltipPosition="left"
                      pTooltip="Durch BK freigegeben"
                    ></i>
                    <i
                      (click)="openRemoveAdminAppointmentReleaseConfirm(item)"
                      *ngIf="item.released_id && item.from_admin"
                      class="pi pi-check-circle clickable-pi-icon color-blue"
                      tooltipStyleClass="tooltip-wider"
                      tooltipPosition="left"
                      pTooltip="Durch Admin freigegeben"
                    ></i>

                    <i
                      (click)="openReleaseAppointmentAdminDialog(item)"
                      *ngIf="!item.released_id"
                      tooltipStyleClass="tooltip-wider"
                      tooltipPosition="left"
                      pTooltip="Einsatz freigeben"
                      class="pi pi-times-circle color-gray clickable-pi-icon"
                    ></i>

                    <i
                      (click)="openFeedbackDialog(item)"
                      *ngIf="item.feedbacks.length > 0"
                      pTooltip="Feedback ansehen"
                      tooltipPosition="left"
                      tooltipStyleClass="tooltip-wider"
                      class="pi pi-envelope color-gray caregiver-appointment-icon-container"
                      style="font-size: 17px"
                    >
                      <p-badge [value]="item.feedbacks.length"></p-badge>
                    </i>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <!-- CHECK -->
        <div
          *ngIf="activeTab === 'CHECK'"
          class="sidebar-inline-calendar"
          style="padding: 20px"
        >
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <p-checkbox
                (onChange)="dataChangedForCheckedStatus()"
                label="Unterschrift des Patient"
                name="has_signature"
                [binary]="true"
                [(ngModel)]="data.proof_status.has_signature"
              ></p-checkbox>
            </div>
            <div class="p-field-checkbox">
              <p-checkbox
                (onChange)="dataChangedForCheckedStatus()"
                label="Handzeichen vorhanden"
                name="has_hand_sign"
                [binary]="true"
                [(ngModel)]="data.proof_status.has_hand_sign"
              ></p-checkbox>
            </div>
            <div class="p-field-checkbox">
              <p-checkbox
                (onChange)="dataChangedForCheckedStatus()"
                label="Summe der Stunden ist korrekt"
                name="has_sum_hours"
                [binary]="true"
                [(ngModel)]="data.proof_status.has_sum_hours"
              ></p-checkbox>
            </div>
          </div>
        </div>

        <!-- CHECK für checkbox "ohne LNW berechnen" -->
        <div
          *ngIf="activeTab === 'CHECK' && !hasMedia"
          class="sidebar-inline-calendar"
          style="padding: 20px; margin-top: auto"
        >
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <p-checkbox
                label="Abrechenbar ohne Leistungsnachweis"
                name="without_proof"
                [binary]="true"
                [(ngModel)]="data.proof_status.without_proof"
              ></p-checkbox>
            </div>
          </div>
        </div>

        <!-- BUDGET -->
        <div *ngIf="activeTab === 'BUDGET'" class="sidebar-inline-calendar">
          <p-table [value]="data.budgets">
            <ng-template pTemplate="header">
              <tr>
                <th>Budget</th>
                <th>Start</th>
                <th>Stunden</th>
                <th>Kosten</th>
                <th>Rest</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
              <tr>
                <td>{{ item.type_formatted }}</td>
                <td>
                  <div style="display: flex; align-items: center; gap: 5px">
                    {{ item.budget_start }}
                    <i
                      *ngIf="item.is_overwritten"
                      class="pi pi-comment color-blue"
                    ></i>
                  </div>
                </td>
                <td>{{ item.amount_hours_real }}</td>
                <td style="display: flex; align-items: center; gap: 5px">
                  {{ item.costs }}
                  <i
                    class="pi pi-info-circle color-gray"
                    style="font-size: 15px"
                    [pTooltip]="item.current_price"
                  ></i>
                </td>
                <td>
                  <div
                    style="display: flex; align-items: center; gap: 5px"
                    [class.color-alert]="item.is_negative"
                  >
                    {{ item.budget_end }}
                    <i
                      class="pi pi-exclamation-triangle"
                      *ngIf="item.is_negative"
                    ></i>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <!-- BUDGET -->
        <div
          *ngIf="activeTab === 'BUDGET'"
          class="sidebar-inline-calendar"
          style="padding: 20px"
        >
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <p-checkbox
                (onChange)="dataChangedForBudgetStatus()"
                label="Budget geprüft"
                name="has_budget_checked"
                [binary]="true"
                [(ngModel)]="data.proof_status.has_budget_checked"
              ></p-checkbox>
            </div>

            <div class="p-field-checkbox" *ngIf="hasBudgetIssueRework">
              <p-checkbox
                (onChange)="dataChangedForBudgetStatus()"
                label="Budget trotz Minus bestätigen"
                name="has_confirm_minus_budget_checked"
                [binary]="true"
                [(ngModel)]="data.proof_status.has_confirm_minus_budget_checked"
              ></p-checkbox>
            </div>
          </div>
        </div>

        <!-- INVOICE -->
        <div
          *ngIf="activeTab === 'INVOICE'"
          class="sidebar-inline-calendar"
          style="padding: 20px"
        >
          <div class="proof-shipping-items">
            <!-- Rechnungsempfänger -->
            <div class="proof-shipping-item">
              <div class="p-fluid p-formgrid p-grid without-border">
                <div class="p-field p-col-9">
                  <label for="invoice_receiver">
                    <i class="pi pi-user"></i>
                    <strong>Rechnungsempfänger</strong>

                    <button
                      pButton
                      (click)="invoiceSettingUsed()"
                      *ngIf="data.invoice_setting"
                      label="Aus History übernehmen"
                      type="button"
                      class="p-button p-button-outlined p-button-small"
                      style="width: auto"
                      icon="pi pi-clock"
                    ></button>
                  </label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Leistungsnehmer"
                        name="invoice_receiver"
                        (onClick)="invoiceReceiverChanged()"
                        [(ngModel)]="invoiceValues.invoice_receiver"
                        value="patient"
                      ></p-radioButton>
                    </div>
                    <div
                      class="p-field-radiobutton"
                      style="gap: 5px"
                      *ngIf="data.patient_care_insurance.id"
                    >
                      <p-radioButton
                        label="Pflegekasse"
                        name="invoice_receiver"
                        (onClick)="invoiceReceiverChanged()"
                        [(ngModel)]="invoiceValues.invoice_receiver"
                        value="care_insurance"
                      ></p-radioButton>
                      <i
                        class="pi pi-exclamation-triangle color-alert"
                        pTooltip="Beihilfe"
                        *ngIf="isBeihilfe"
                      ></i>
                    </div>
                    <div
                      class="p-field-radiobutton"
                      *ngIf="data.patient_cost_unit.id"
                    >
                      <p-radioButton
                        label="Kostenträger"
                        name="invoice_receiver"
                        (onClick)="invoiceReceiverChanged()"
                        [(ngModel)]="invoiceValues.invoice_receiver"
                        value="cost_unit"
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Individuell"
                        name="invoice_receiver"
                        (onClick)="invoiceReceiverChanged()"
                        [(ngModel)]="invoiceValues.invoice_receiver"
                        value="other"
                      ></p-radioButton>
                    </div>
                  </div>
                </div>

                <!-- Auswahl Kostenträger wenn kein AP vorhanden -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    invoiceValues.invoice_receiver === 'cost_unit' &&
                    costUnitHasNoContactPersons
                  "
                >
                  <label style="font-size: 15px"
                    ><strong>Kostenträger</strong></label
                  >
                  <a
                    target="_blank"
                    style="color: currentColor"
                    routerLink="/multipliers/{{ data.patient_cost_unit.id }}"
                  >
                    <span
                      >{{ data.patient_cost_unit.name }}
                      <i
                        style="font-size: 12px; margin: 0 0 0 5px"
                        class="color-gray pi pi-arrow-up-right"
                      ></i>
                    </span>
                  </a>
                </div>

                <!-- Auswahl Kostenträger wenn AP vorhanden sind -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    invoiceValues.invoice_receiver === 'cost_unit' &&
                    !costUnitHasNoContactPersons
                  "
                >
                  <label
                    for="invoice-receiver-cost-unit"
                    style="font-size: 15px; margin: 0 0 8px 0"
                    ><strong>Auswahl</strong></label
                  >
                  <p-dropdown
                    inputId="invoice-receiver-cost-unit"
                    name="invoice-receiver-cost-unit"
                    [options]="costUnitReceiverOptions"
                    [(ngModel)]="invoiceValues.cost_unit_receiver"
                    (onChange)="costUnitReceiverSelected()"
                    scrollHeight="500px"
                    appendTo="body"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                    </ng-template>
                  </p-dropdown>
                </div>

                <!-- Auswahl Pflegekasse -->
                <div
                  class="p-field p-col-3"
                  *ngIf="invoiceValues.invoice_receiver === 'care_insurance'"
                >
                  <label><strong>Pflegekasse</strong></label>
                  <a
                    target="_blank"
                    style="color: currentColor"
                    routerLink="/multipliers/{{
                      data.patient_care_insurance.id
                    }}"
                  >
                    <span
                      >{{ data.patient_care_insurance.name }}
                      <i
                        style="font-size: 12px; margin: 0 0 0 5px"
                        class="color-gray pi pi-arrow-up-right"
                      ></i>
                    </span>
                  </a>
                </div>

                <!-- Auswahl Patient -->
                <div
                  class="p-field p-col-3"
                  *ngIf="invoiceValues.invoice_receiver === 'patient'"
                >
                  <label style="margin: 0 0 8px 0"
                    ><strong>Auswahl</strong></label
                  >
                  <p-dropdown
                    inputId="patient_receiver"
                    name="patient_receiver"
                    [options]="patientReceiverOptions"
                    [(ngModel)]="invoiceValues.patient_receiver"
                    (onChange)="patientReceiverSelected()"
                    scrollHeight="500px"
                    appendTo="body"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>

              <!-- Auswahl Individuell -->
              <div
                class="p-fluid p-formgrid p-grid without-border border-top"
                style="padding-top: 10px"
                *ngIf="invoiceValues.invoice_receiver === 'other'"
              >
                <div class="p-field p-col-4">
                  <label
                    for="individual_form_of_address"
                    style="margin: 0 0 8px 0"
                    ><strong>Anrede</strong></label
                  >
                  <p-dropdown
                    [(ngModel)]="invoiceValues.individual_form_of_address"
                    [options]="formOfAddress"
                    inputId="individual_form_of_address"
                    name="individual_form_of_address"
                    placeholder="Bitte auswählen"
                    (onChange)="getPreviewLink()"
                  ></p-dropdown>
                </div>

                <div class="p-field p-col-4">
                  <label for="individual_first_name" style="margin: 0 0 8px 0"
                    ><strong>Vorname</strong></label
                  >
                  <input
                    id="individual_first_name"
                    name="individual_first_name"
                    [(ngModel)]="invoiceValues.individual_first_name"
                    (change)="getPreviewLink()"
                    type="text"
                    pInputText
                  />
                </div>

                <div class="p-field p-col-4">
                  <label for="individual_last_name" style="margin: 0 0 8px 0"
                    ><strong>Nachname</strong></label
                  >
                  <input
                    id="individual_last_name"
                    name="individual_last_name"
                    [(ngModel)]="invoiceValues.individual_last_name"
                    type="text"
                    (change)="getPreviewLink()"
                    pInputText
                  />
                </div>
              </div>

              <!-- Auswahl Individuell -->
              <div
                class="p-fluid p-formgrid p-grid without-border"
                style="padding: 0"
                *ngIf="invoiceValues.invoice_receiver === 'other'"
              >
                <div class="p-field p-col-4">
                  <label
                    for="individual_street_house_number"
                    style="margin: 0 0 8px 0"
                    ><strong>Straße / Hausnummer</strong></label
                  >
                  <input
                    id="individual_street_house_number"
                    name="individual_street_house_number"
                    [(ngModel)]="invoiceValues.individual_street_house_number"
                    type="text"
                    (change)="getPreviewLink()"
                    pInputText
                  />
                </div>

                <div class="p-field p-col-4">
                  <label for="individual_zipcode" style="margin: 0 0 8px 0"
                    ><strong>PLZ</strong></label
                  >
                  <input
                    id="individual_zipcode"
                    name="individual_zipcode"
                    [(ngModel)]="invoiceValues.individual_zipcode"
                    type="text"
                    (change)="getPreviewLink()"
                    pInputText
                  />
                </div>

                <div class="p-field p-col-4">
                  <label for="individual_city" style="margin: 0 0 8px 0"
                    ><strong>Ort</strong></label
                  >
                  <span class="p-input-icon-right">
                    <!--                <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>-->
                    <input
                      id="individual_city"
                      name="individual_city"
                      [(ngModel)]="invoiceValues.individual_city"
                      type="text"
                      pInputText
                      (change)="getPreviewLink()"
                    />
                  </span>
                </div>
              </div>

              <!-- Auswahl Individuell -->
              <div
                class="p-fluid p-formgrid p-grid without-border"
                style="padding: 0"
                *ngIf="
                  invoiceValues.invoice_receiver === 'other' &&
                  invoiceValues.send_type === 'email'
                "
              >
                <div class="p-field p-col-4">
                  <label for="individual_email" style="margin: 0 0 8px 0"
                    ><strong>E-Mail</strong></label
                  >
                  <input
                    id="individual_email"
                    name="individual_email"
                    [(ngModel)]="invoiceValues.individual_email"
                    type="text"
                    pInputText
                    (change)="getPreviewLink()"
                  />
                </div>
              </div>
            </div>

            <!-- Abrechnungsstelle -->
            <div class="proof-shipping-item">
              <div class="p-fluid p-formgrid p-grid without-border">
                <div class="p-field p-col-8">
                  <label
                    ><i class="pi pi-home"></i>
                    <strong>Abrechnungsstelle</strong></label
                  >
                  <div class="p-formgroup-inline">
                    <div
                      class="p-field-radiobutton"
                      *ngIf="
                        (invoiceValues.invoice_receiver === 'patient' ||
                          invoiceValues.invoice_receiver === 'other') &&
                        data.patient_first_sepa
                      "
                    >
                      <p-radioButton
                        label="SEPA"
                        name="accounting_type"
                        [(ngModel)]="invoiceValues.accounting_type"
                        value="SEPA"
                        (onClick)="getPreviewLink()"
                      ></p-radioButton>
                    </div>

                    <div class="p-field-radiobutton" style="gap: 5px">
                      <p-radioButton
                        label="Leistungsnehmer"
                        name="accounting_type"
                        [(ngModel)]="invoiceValues.accounting_type"
                        value="patient"
                        (onClick)="getPreviewLink()"
                      ></p-radioButton>

                      <i
                        class="pi pi-exclamation-triangle color-alert"
                        pTooltip="SEPA"
                        *ngIf="
                          (invoiceValues.invoice_receiver === 'patient' ||
                            invoiceValues.invoice_receiver === 'other') &&
                          data.patient_first_sepa
                        "
                      ></i>
                    </div>
                    <div class="p-field-radiobutton" style="gap: 5px">
                      <p-radioButton
                        label="Abrechnungszentrum"
                        name="accounting_type"
                        [(ngModel)]="invoiceValues.accounting_type"
                        value="accounting_center"
                        (onClick)="getPreviewLink()"
                      ></p-radioButton>

                      <i
                        class="pi pi-exclamation-triangle color-alert"
                        pTooltip="SEPA"
                        *ngIf="
                          (invoiceValues.invoice_receiver === 'patient' ||
                            invoiceValues.invoice_receiver === 'other') &&
                          data.patient_first_sepa
                        "
                      ></i>

                      <i
                        class="pi pi-exclamation-triangle color-alert"
                        pTooltip="Beihilfe"
                        *ngIf="isBeihilfe"
                      ></i>
                    </div>
                    <div
                      class="p-field-radiobutton"
                      *ngIf="invoiceValues.invoice_receiver === 'cost_unit'"
                    >
                      <p-radioButton
                        label="Kostenträger"
                        name="accounting_type"
                        [(ngModel)]="invoiceValues.accounting_type"
                        value="cost_unit"
                        (onClick)="getPreviewLink()"
                      ></p-radioButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Versandoptionen -->
            <div class="proof-shipping-item">
              <div class="p-fluid p-formgrid p-grid without-border">
                <div class="p-field p-col-12">
                  <label>
                    <i class="pi pi-send"></i> <strong>Versandoptionen</strong>

                    <!--                    <div class="p-field-checkbox" style="margin-left: auto">-->
                    <!--                      <p-checkbox-->
                    <!--                        label="mit Kopie"-->
                    <!--                        [binary]="true"-->
                    <!--                        name="with_copy"-->
                    <!--                      ></p-checkbox>-->
                    <!--                    </div>-->
                  </label>
                </div>

                <div class="p-field p-col-6">
                  <label><strong>Versand per</strong></label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        (onClick)="buildAddressReceiverOptions()"
                        label="E-Mail"
                        name="send_type"
                        value="email"
                        [(ngModel)]="invoiceValues.send_type"
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        (onClick)="buildAddressReceiverOptions()"
                        label="Post"
                        name="send_type"
                        value="post"
                        [(ngModel)]="invoiceValues.send_type"
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        (onClick)="buildAddressReceiverOptions()"
                        label="Kein Versand"
                        name="send_type"
                        value="no_sending"
                        [(ngModel)]="invoiceValues.send_type"
                      ></p-radioButton>
                    </div>
                  </div>
                </div>

                <!-- Mail Empfänger bei Pflegekasse -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    invoiceValues.invoice_receiver === 'care_insurance' &&
                    invoiceValues.send_type === 'email'
                  "
                >
                  <label><strong>E-Mail</strong></label>
                  <span *ngIf="data.patient_care_insurance.email">{{
                    data.patient_care_insurance.email
                  }}</span>
                  <span
                    class="color-alert"
                    *ngIf="!data.patient_care_insurance.email"
                    >Keine E-Mail</span
                  >
                </div>

                <!-- Mail Empfänger bei Individuell -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    invoiceValues.invoice_receiver === 'other' &&
                    invoiceValues.send_type === 'email'
                  "
                >
                  <label><strong>E-Mail</strong></label>
                  <span *ngIf="invoiceValues.individual_email">{{
                    invoiceValues.individual_email
                  }}</span>
                  <span
                    class="color-alert"
                    *ngIf="!invoiceValues.individual_email"
                    >Bitte E-Mail eintragen</span
                  >
                </div>

                <!-- Mail Empfänger bei Kostenträger wenn kein AP vorhanden ist -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    invoiceValues.invoice_receiver === 'cost_unit' &&
                    invoiceValues.send_type === 'email' &&
                    costUnitHasNoContactPersons
                  "
                >
                  <label><strong>E-Mail</strong></label>
                  <span *ngIf="data.patient_cost_unit.email">{{
                    data.patient_cost_unit.email
                  }}</span>
                  <span
                    class="color-alert"
                    *ngIf="!data.patient_cost_unit.email"
                    >Keine E-Mail</span
                  >
                </div>

                <!-- Mail Empfänger bei Kostenträger wenn AP vorhanden sind -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    invoiceValues.invoice_receiver === 'cost_unit' &&
                    invoiceValues.send_type === 'email' &&
                    !costUnitHasNoContactPersons
                  "
                >
                  <label
                    style="margin: 0 0 8px 0"
                    for="cost-unit-email-receiver"
                    ><strong>E-Mail</strong></label
                  >
                  <p-dropdown
                    inputId="cost-unit-email-receiver"
                    name="cost-unit-email-receiver"
                    [options]="costUnitEmailReceiverOptions"
                    [(ngModel)]="invoiceValues.cost_unit_email_receiver"
                    scrollHeight="500px"
                    appendTo="body"
                    (onChange)="getPreviewLink()"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                      <span>{{ receiver.email }}</span>
                    </ng-template>
                  </p-dropdown>
                </div>

                <!-- Empfänger wenn Leistungsnehmer ausgewählt ist -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    invoiceValues.invoice_receiver === 'patient' &&
                    (invoiceValues.send_type === 'email' ||
                      invoiceValues.send_type === 'post')
                  "
                >
                  <label for="address-receiver" style="margin: 0 0 8px 0"
                    ><strong>Empfänger</strong></label
                  >
                  <p-dropdown
                    inputId="address-receiver"
                    name="address-receiver"
                    (onChange)="addressReceiverSelected()"
                    [options]="addressReceiverOptions"
                    [(ngModel)]="invoiceValues.receiver"
                    scrollHeight="500px"
                    appendTo="body"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                      <span *ngIf="invoiceValues.send_type === 'email'">{{
                        receiver.email
                      }}</span>
                    </ng-template>
                  </p-dropdown>
                </div>

                <!-- CC -->
                <div
                  class="p-field p-col-3"
                  *ngIf="invoiceValues.send_type === 'email'"
                >
                  <label style="margin: 0 0 8px 0"
                    ><strong>CC an</strong></label
                  >
                  <p-multiSelect
                    inputId="cc"
                    placeholder="Optional"
                    [options]="ccOptions"
                    [filter]="false"
                    [showHeader]="false"
                    name="cc"
                    (onChange)="getPreviewLink()"
                    appendTo="body"
                    scrollHeight="400px"
                    [(ngModel)]="invoiceValues.cc_receivers"
                  ></p-multiSelect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer" *ngIf="!loading && mediaGenerated">
    <i></i>
    <a target="_blank" [href]="generatedMediaLink">
      <button
        pButton
        label="Dokument herunterladen"
        type="button"
        icon="pi pi-download"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>
  </div>

  <div class="dialog-form-footer" *ngIf="!loading && !mediaGenerated">
    <i></i>

    <div style="display: flex; gap: 10px">
      <button
        pButton
        label="Stornieren"
        class="p-button-danger"
        type="button"
        (click)="stornoInvoice()"
      ></button>

      <button
        (click)="activateSidebar()"
        pButton
        class="p-button-outlined"
        label="Nachbearbeitung"
        icon="pi pi-exclamation-triangle"
      ></button>

      <button
        pButton
        *ngIf="activeTab === 'CHECK'"
        [label]="
          dataForCheckStatusIsValid
            ? 'Prüfung speichern und weiter'
            : 'Prüfung speichern'
        "
        type="submit"
        tooltipStyleClass="tooltip-wider"
        (click)="saveCheckedStatus()"
        [disabled]="
          submitted ||
          hasOpenedReworks ||
          (!hasMedia && !data.proof_status.without_proof)
        "
        [pTooltip]="
          hasOpenedReworks
            ? 'Nachbearbeiten sind noch offen'
            : !hasMedia && !data.proof_status.without_proof
            ? 'Leistungsnachweis fehlt'
            : ''
        "
        [iconPos]="dataForCheckStatusIsValid ? 'right' : 'left'"
        [icon]="
          submitted
            ? 'pi pi-spin pi-spinner'
            : dataForCheckStatusIsValid
            ? 'pi pi-arrow-right'
            : 'pi pi-check'
        "
      ></button>

      <button
        pButton
        *ngIf="activeTab === 'BUDGET'"
        label="Budget speichern und weiter"
        type="submit"
        tooltipStyleClass="tooltip-wider"
        (click)="saveBudgetStatus()"
        [pTooltip]="
          hasOpenedReworks
            ? 'Nachbearbeiten sind noch offen'
            : dataForBudgetStatusIsValid
            ? ''
            : 'Häkchen fehlt oder Budget ist im Minus'
        "
        [disabled]="
          submitted || !dataForBudgetStatusIsValid || hasOpenedReworks
        "
        iconPos="right"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-arrow-right'"
      ></button>

      <a
        *ngIf="activeTab === 'INVOICE' && invoiceValues.send_type === 'email'"
        target="_blank"
        (click)="clickedOnPreviewMail = true"
        [href]="mailPreviewSrc"
      >
        <button
          pButton
          label="Mail Vorschau"
          type="button"
          [pTooltip]="isMailPreviewDisabled() ? 'Keine E-Mail ausgewählt' : ''"
          [disabled]="isMailPreviewDisabled()"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a
        *ngIf="activeTab === 'INVOICE'"
        target="_blank"
        (click)="clickedOnPreviewPdf = true"
        [href]="pdfPreviewSrc"
      >
        <button
          pButton
          label="PDF Vorschau"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a
        *ngIf="activeTab === 'INVOICE' && data.proof_status.proof_with_stamp"
        target="_blank"
        (click)="clickedOnPreviewLnw = true"
        [href]="imgSrcLnw"
      >
        <button
          pButton
          [disabled]="!data.proof_status.proof_with_stamp"
          [pTooltip]="
            !data.proof_status.proof_with_stamp
              ? 'Kein LNW mit Stempel vorhanden'
              : ''
          "
          label="LNW Vorschau"
          tooltipStyleClass="tooltip-wider"
          tooltipPosition="left"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <p-splitButton
        *ngIf="activeTab === 'INVOICE'"
        label="Entwurf speichern"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        [model]="buttonItems"
        (onClick)="saveInvoiceStatus()"
        appendTo="body"
        tooltipStyleClass="tooltip-wider"
        [disabled]="submitted || hasOpenedReworks"
        [pTooltip]="hasOpenedReworks ? 'Nachbearbeiten sind noch offen' : ''"
      >
      </p-splitButton>
    </div>
  </div>
</form>
