import { UserModel } from './user.model'

export class UserSystemDetailModel {
  id: number | null = null
  form_of_address = ''
  first_name = ''
  last_name = ''
  notice = ''
  user: UserModel = new UserModel()
}
