import { Component, OnInit } from '@angular/core'
import { ToastService } from '../../../services/toast.service'
import { TitleService } from '../../../services/title.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { DocumentService } from '../../../services/document.service'
import { HospitationDialogComponent } from '../../../components/dialogs/hospitation-dialog/hospitation-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { ShowMyInternEventDialogComponent } from '../../../components/dialogs/show-my-intern-event-dialog/show-my-intern-event-dialog.component'
import { InternEventModel } from '../../../models/intern-event/intern-event.model'
import { InternEventService } from '../../../services/intern-event.service'

@Component({
  selector: 'app-user-caregiver-intern-events',
  templateUrl: './user-caregiver-intern-events.component.html',
})
export class UserCaregiverInternEventsComponent implements OnInit {
  public loading = true
  public contentLoading = true

  public data: any[] = []

  constructor(
    public userCaregiverService: UserCaregiverService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private internEventService: InternEventService,
    public documentService: DocumentService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Meine Events`)

    this.loadData()
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.contentLoading = true

    this.internEventService
      .loadAllEventsForMeAsCaregiver()
      .subscribe((data: InternEventModel[]) => {
        this.data = data

        this.contentLoading = false
        this.loading = false
      })
  }

  public openAddInternEventDialog(event = null): void {
    this.dialogService.open(ShowMyInternEventDialogComponent, {
      header: 'Mein Event',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
      data: {
        event,
      },
    })
  }
}
