<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header is-sticky">
    <div class="content-header-patients-tabs">
      <a
        href="/patients/{{ patients.first_patient.id }}/history"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.first_patient.appointment_lock"
        ></i>
        <i class="pi pi-user"></i>
        {{ patients.first_patient.full_name }}

        <i
          *ngIf="patients.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.first_patient.status }}"
          >{{ patients.first_patient.status_string }}</i
        >

        <i
          *ngIf="patients.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
      <a
        href="/patients/{{ patients.second_patient.id }}/history"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="patients.second_patient"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.second_patient.appointment_lock"
        ></i>
        <i class="pi pi-users"></i>
        {{ patients.second_patient.full_name }}

        <i
          *ngIf="patients.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.second_patient.status }}"
          >{{ patients.second_patient.status_string }}</i
        >

        <i
          *ngIf="patients.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
    </div>
    <div class="content-header-buttons">
      <p-checkbox
        (onChange)="loadHistory(false)"
        label="nur Planung"
        [binary]="true"
        [(ngModel)]="onlyPlanning"
        name="only_planning"
      ></p-checkbox>

      <p-checkbox
        label="nur wichtige Ereignisse"
        [binary]="true"
        [(ngModel)]="onlyImportant"
        name="only_assigned"
      ></p-checkbox>

      <button
        pButton
        label="Neuer Eintrag"
        (click)="openManualHistoryDialog(false)"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        iconPos="left"
      ></button>

      <p-multiSelect
        [styleClass]="
          filterTypes.length !== selectedFilterTypes.length
            ? 'btn-is-highlighted-alert'
            : ''
        "
        inputId="history-filter"
        name="history-filter"
        [options]="filterTypes"
        optionLabel="name"
        optionValue="value"
        scrollHeight="600px"
        (onChange)="setTypeIsHidden()"
        [filter]="true"
        [showHeader]="true"
        [(ngModel)]="selectedFilterTypes"
      >
        <ng-template let-value pTemplate="selectedItems"> Filter </ng-template>

        <ng-template let-type pTemplate="item">
          {{ type.name }}
        </ng-template>
      </p-multiSelect>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div class="content-body without-padding auto-height">
    <p-table
      #dt
      [globalFilterFields]="[
        'type_text',
        'phone_call.comment',
        'manual_title',
        'manual_description',
        'appointment_html_table',
        'content.real_from_formatted',
        'content_before.real_from_formatted',
        'content.caregiver.first_name',
        'content.caregiver.last_name',
        'content_before.caregiver.last_name',
        'content_before.caregiver.first_name',
        'content.persplan_function_complete',
        'content_before.persplan_function_complete'
      ]"
      [value]="patients[this.selectedPatient].histories"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 200px" pSortableColumn="type_text">
            Typ <p-sortIcon field="type_text"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_at">
            am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_from_name">
            von <p-sortIcon field="created_from_name"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
          <th>Informationen</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history>
        <!-- TODO: Achtung bei [hidden] -> Falls irgendwann Pagination reinkommt, wird das nicht mehr funktionieren -->
        <tr
          [class.background-hightlight-yellow]="history.important"
          [class.background-hightlight-blue]="
            history.available_for_recommendation
          "
          [hidden]="
            (onlyImportant && !history.important) || typeIsHidden[history.type]
          "
        >
          <td>
            <strong>
              {{ history.type_text }}
            </strong>
          </td>

          <td>{{ history.occurred_at_string }} Uhr</td>

          <td>{{ history.created_from_name || "??" }}</td>

          <td></td>
          <td>
            <span
              class="history-information"
              *ngIf="history.type === 'PHONE_CALL'"
            >
              <i>
                <ng-container *ngIf="history.phone_call.caregiver_from_customer_call && history.phone_call.caregiver">
                  <strong>BK:</strong>
                  <a target="_blank" routerLink="/caregivers/{{ history.phone_call.caregiver_id }}">
                    {{ history.phone_call.caregiver.full_name }}
                  </a>
                </ng-container>

                <strong>Name:</strong>
                {{ history.phone_call.phone_caller_name }}
                <strong>Dauer:</strong>
                {{ history.phone_call.duration || "N/A" }}
              </i>
              <strong
                class="history-text"
                *ngIf="history.phone_call?.from_appointment"
                >Terminübergabe</strong
              >
              <strong
                class="history-text"
                *ngIf="history.phone_call?.not_reached"
                >Nicht erreicht</strong
              >
              <p class="history-text" *ngIf="history.phone_call?.comment">
                {{ history.phone_call.comment }}
              </p>
            </span>

            <span
              class="history-information"
              *ngIf="
                history.type === 'CONTACT_PERSON_CREATED' ||
                history.type === 'CONTACT_PERSON_UPDATED' ||
                history.type === 'PATIENT_CHANGED' ||
                history.type === 'CONTACT_PERSON_DEACTIVATED'
              "
            >
              <i><strong>Name:</strong> {{ history.manual_description }}</i>
            </span>

            <span
              class="history-information"
              *ngIf="
                history.type === 'MANUAL' ||
                history.type === 'STATUS_CHANGED' ||
                history.type === 'CUSTOMER_SERVICE_CHANGED' ||
                history.type === 'TODO_CREATED' ||
                history.type === 'TODO_DELETED' ||
                history.type === 'TODO_FINISHED' ||
                history.type === 'TODO_UPDATED' ||
                history.type === 'VETO_CREATED' ||
                history.type === 'VETO_DELETED' ||
                history.type === 'VETO_UPDATED' ||
                history.type === 'BUDGET_CHANGED' ||
                history.type === 'BUDGET_REMOVED_FROM_LIST' ||
                history.type === 'OFFER_SENT' ||
                history.type === 'DOCUMENT_CHANGED' ||
                history.type === 'DOCUMENT_REMOVED' ||
                history.type === 'DOCUMENT_ARCHIVED' ||
                history.type === 'DOCUMENT_UNARCHIVED' ||
                history.type === 'PAUSED' ||
                history.type === 'PAUSE_REACTIVATED' ||
                history.type === 'APPOINTMENTS_SENT' ||
                history.type === 'DESIRED_DATE_CREATED' ||
                history.type === 'DESIRED_DATE_UPDATED' ||
                history.type === 'DESIRED_DATE_DELETED' ||
                history.type === 'CANCELLED_DATE_CREATED' ||
                history.type === 'CANCELLED_DATE_UPDATED' ||
                history.type === 'CANCELLED_DATE_DELETED' ||
                history.type === 'CONSULTING_CREATED' ||
                history.type === 'CONSULTING_CONFIRMED' ||
                history.type === 'CONSULTING_UPDATED' ||
                history.type === 'CONSULTING_DELETED' ||
                history.type === 'CREATED' ||
                history.type === 'INVOICE_OPENED' ||
                history.type === 'INVOICE_STORNO' ||
                history.type === 'ACCOUNTING_RETURN_CREATED' ||
                history.type === 'PATIENT_PRICE_CREATED' ||
                history.type === 'ACCOUNTING_RETURN_DELETED' ||
                history.type === 'LETTER_SENT' ||
                history.type === 'PATIENT_TIMES_CHANGED' ||
                history.type === 'APPOINTMENT_FEEDBACK_FINISHED_WITH_HISTORY' ||
                history.type === 'PATIENT_PLANNING_DELETED' ||
                history.type === 'PATIENT_PLANNING_CHANGED' ||
                history.type === 'PATIENT_PLANNING_CREATED' ||
                history.type === 'PATIENT_PLANNING_PAUSED' ||
                history.type === 'PATIENT_PLANNING_STORNO' ||
                history.type === 'CAREGIVER_PLANNING_ILL' ||
                history.type === 'CAREGIVER_PLANNING_INACTIVE' ||
                history.type === 'SYSTEM_MESSAGE_SENT' ||
                history.type === 'CAREGIVER_PLANNING_INACTIVE_EMPLOYED'
              "
            >
              <i
                ><b>{{ history.manual_title }}</b
                ><i
                  class="pi pi-file"
                  tooltipPosition="left"
                  pTooltip="Mit Dokument"
                  *ngIf="history.documents?.length > 0"
                ></i
              ></i>
              <p
                class="history-text"
                [innerHTML]="history.manual_description"
                *ngIf="history.manual_description"
              ></p>

              <!-- Daten für die Planung -->
              <p
                class="history-text"
                *ngIf="
                  history.type === 'PATIENT_PLANNING_DELETED' ||
                  history.type === 'PATIENT_PLANNING_CHANGED' ||
                  history.type === 'PATIENT_PLANNING_CREATED' ||
                  history.type === 'PATIENT_PLANNING_PAUSED' ||
                  history.type === 'PATIENT_PLANNING_STORNO' ||
                  history.type === 'CAREGIVER_PLANNING_ILL' ||
                  history.type === 'CAREGIVER_PLANNING_INACTIVE' ||
                  history.type === 'CAREGIVER_PLANNING_INACTIVE_EMPLOYED'
                "
              >
                <!-- Datum und Uhrzeit wenn ETWAS geändert wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="
                    history.type === 'PATIENT_PLANNING_CHANGED' &&
                    (history.content_before.real_from !=
                      history.content.real_from ||
                      history.content_before.real_from_h !=
                        history.content.real_from_h ||
                      history.content_before.real_to_h !=
                        history.content.real_to_h)
                  "
                >
                  Einsatz:
                  {{ history.content_before.real_from_formatted }},
                  {{ history.content_before.real_from_h }} -
                  {{ history.content_before.real_to_h }}
                  <i class="pi pi-arrow-right"></i>
                  <strong>
                    {{ history.content.real_from_formatted }},
                    {{ history.content.real_from_h }} -
                    {{ history.content.real_to_h }}
                  </strong>
                </span>

                <!-- Datum wenn Termin erstellt wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="history.type === 'PATIENT_PLANNING_CREATED'"
                >
                  Einsatz:
                  {{ history.content.real_from_formatted }},
                  {{ history.content.real_from_h }} -
                  {{ history.content.real_to_h }}
                </span>

                <!-- Datum wenn Termin gelöscht wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="
                    [
                      'PATIENT_PLANNING_STORNO',
                      'PATIENT_PLANNING_PAUSED',
                      'PATIENT_PLANNING_DELETED',
                      'CAREGIVER_PLANNING_ILL',
                      'CAREGIVER_PLANNING_INACTIVE',
                      'CAREGIVER_PLANNING_INACTIVE_EMPLOYED'
                    ].includes(history.type)
                  "
                >
                  Einsatz:
                  {{ history.content.real_from_formatted }},
                  {{ history.content.real_from_h }} -
                  {{ history.content.real_to_h }}
                </span>

                <!-- Betreuungskraft wenn Termin gelöscht wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="history.type === 'PATIENT_PLANNING_DELETED'"
                >
                  Betreuungskraft:
                  {{ history.content.caregiver.first_last_name }}
                </span>

                <!-- Betreuungskraft wenn Termin erstellt wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="history.type === 'PATIENT_PLANNING_CREATED'"
                >
                  Betreuungskraft:
                  {{ history.content.caregiver.first_last_name }}
                </span>

                <!-- Budget wenn Termin erstellt wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="history.type === 'PATIENT_PLANNING_CREATED'"
                >
                  Budget:
                  {{ history.content.persplan_function_complete }}
                </span>

                <!-- Budget wenn Termin gelöscht wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="history.type === 'PATIENT_PLANNING_DELETED'"
                >
                  Budget:
                  {{ history.content.persplan_function_complete }}
                </span>

                <!-- Bemerkung wenn Termin erstellt wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="
                    history.content.task &&
                    history.type === 'PATIENT_PLANNING_CREATED'
                  "
                >
                  Bemerkung:
                  <i
                    style="margin: 0 0 0 10px"
                    class="pi pi-info-circle"
                    [pTooltip]="history.content.task"
                  ></i>
                </span>

                <!-- Datum und Uhrzeit wenn NICHTS geändert wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="
                    history.type === 'PATIENT_PLANNING_CHANGED' &&
                    history.content_before.real_from ==
                      history.content.real_from &&
                    history.content_before.real_from_h ==
                      history.content.real_from_h &&
                    history.content_before.real_to_h ==
                      history.content.real_to_h
                  "
                >
                  Einsatz:
                  {{ history.content.real_from_formatted }},
                  {{ history.content.real_from_h }} -
                  {{ history.content.real_to_h }}
                </span>

                <!-- Betreuungskraft wenn Termin geändert wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="
                    history.type === 'PATIENT_PLANNING_CHANGED' &&
                    history.content_before.caregiver.id !=
                      history.content.caregiver.id
                  "
                >
                  Betreuungskraft:
                  {{ history.content_before.caregiver.first_last_name }}
                  <i class="pi pi-arrow-right"></i>
                  <strong>
                    {{ history.content.caregiver.first_last_name }}
                  </strong>
                </span>

                <!-- Budget wenn Termin geändert wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="
                    history.type === 'PATIENT_PLANNING_CHANGED' &&
                    history.content_before.persplan_function_complete !=
                      history.content.persplan_function_complete
                  "
                >
                  Budget:
                  {{ history.content_before.persplan_function_complete }}
                  <i class="pi pi-arrow-right"></i>
                  <strong>
                    {{ history.content.persplan_function_complete }}
                  </strong>
                </span>

                <!-- Bemerkung wenn Termin geändert wurde -->
                <span
                  class="history-appointment-item"
                  *ngIf="
                    history.type === 'PATIENT_PLANNING_CHANGED' &&
                    history.content.task &&
                    history.content_before.task != history.content.task
                  "
                >
                  Bemerkung:
                  <i
                    style="margin: 0 0 0 10px"
                    class="pi pi-info-circle"
                    [pTooltip]="history.content_before.task"
                  ></i>
                  <i class="pi pi-arrow-right"></i>
                  <i
                    class="pi pi-info-circle"
                    [pTooltip]="history.content.task"
                  ></i>
                </span>
              </p>

              <!-- Terminversand -->
              <p
                class="history-text"
                *ngIf="history.appointment_html_table"
                [innerHTML]="history.appointment_html_table"
              ></p>
            </span>
          </td>
          <td class="table-action">
            <i
              (click)="openDiffView(history.id)"
              tooltipPosition="left"
              pTooltip="Änderungen ansehen"
              *ngIf="
                history.type === 'CHANGED' ||
                history.type === 'NOTICE_CHANGED' ||
                history.type === 'NOTICE_ACCOUNTING_CHANGED' ||
                history.type === 'CUSTOMER_CHANGED' ||
                history.type === 'PATIENT_CHANGED' ||
                history.type === 'QM_CHANGED' ||
                history.type === 'HOUSEHOLD_CHANGED' ||
                history.type === 'CONTACT_PERSON_UPDATED'
              "
              class="pi pi-eye"
            ></i>
            <i
              (click)="openOfferMail(history.historiable_id)"
              pTooltip="E-Mail ansehen"
              tooltipPosition="left"
              *ngIf="
                history.type === 'OFFER_SENT' &&
                history.manual_title.includes('Mail')
              "
              class="pi pi-eye"
            ></i>

            <i
              (click)="openAppointmentsMail(history.historiable_id)"
              pTooltip="E-Mail ansehen"
              tooltipPosition="left"
              *ngIf="
                history.type === 'APPOINTMENTS_SENT' &&
                history.manual_title.includes('Mail')
              "
              class="pi pi-eye"
            ></i>

            <i
              (click)="openLetterMail(history.historiable_id)"
              pTooltip="E-Mail ansehen"
              tooltipPosition="left"
              *ngIf="
                history.type === 'LETTER_SENT' &&
                history.manual_title.includes('Mail')
              "
              class="pi pi-eye"
            ></i>
            <i
              *ngIf="
                history.type === 'TODO_CREATED' ||
                history.type === 'TODO_FINISHED' ||
                history.type === 'TODO_UPDATED'
              "
              class="pi pi-eye"
              pTooltip="Todo ansehen"
              tooltipPosition="left"
              (click)="openShowTodoDialog(history.todo)"
            ></i>
            <i
              *ngIf="history.type === 'PHONE_CALL'"
              class="pi pi-pencil"
              (click)="openPhonecallEditModal(history.phone_call)"
            ></i>
            <i
              *ngIf="history.type === 'MANUAL'"
              (click)="openManualHistoryDialog(true, history)"
              class="pi pi-pencil"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
