import { PatientDetailModel } from './patient-detail.model'
import { UserModel } from '../user/user.model'

export class CustomerDetailModel {
  id: number | null = null
  notice = ''
  notice_changed_date = ''
  notice_accounting = ''
  notice_accounting_changed_date = ''
  user: UserModel | null = null
  created_as_consulting_assignment = false
  consulting_assignment = false
  created_from_multiplier = false
  created_from_caregiver = false
  offer_type = 'OFFER'
  admin_update_clicked_from = null
  admin_update_clicked_at = null
  first_patient: PatientDetailModel = new PatientDetailModel()
  second_patient?: PatientDetailModel = new PatientDetailModel()
}
