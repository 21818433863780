<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-field">
      <label for="file"
        >Nachweis als PDF auswählen {{ isEdit ? "" : "*" }}
        <i
          *ngIf="isEdit"
          class="pi pi-info-circle"
          tooltipStyleClass="tooltip-wider"
          pTooltip="Neue Datei auswählen um vorhandene zu überschreiben"
        ></i
      ></label>
      <input
        id="file"
        name="file"
        type="file"
        (change)="setSelectedFile($event)"
        pInputText
        accept="application/pdf"
        [(ngModel)]="values.selected_file"
        [required]="!isEdit"
        #uploadFile="ngModel"
        [class.p-invalid]="
          uploadFile.invalid && (uploadFile.dirty || uploadFile.touched)
        "
      />
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" style="padding: 0">
      <div class="p-field p-col-8">
        <label
          for="qm-home-visit-date"
          style="display: flex; justify-content: space-between"
        >
          Datum *

          <div class="p-formgroup-inline">
            <div class="p-field-checkbox" style="margin: 0 !important">
              <p-checkbox
                label="Ist ein Nachholtermin"
                name="is_catch_up"
                [binary]="true"
                [(ngModel)]="values.is_catch_up"
              ></p-checkbox>
            </div>
          </div>
        </label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="qm-home-visit-date"
          name="qm-home-visit-date"
          [showButtonBar]="true"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.home_visit_date_string"
          dataType="string"
          #date="ngModel"
          [showIcon]="true"
          [required]="true"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-4">
        <label for="from_time">Uhrzeit *</label>
        <p-calendar
          inputId="from_time"
          name="from_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.home_visit_time"
          dataType="string"
          [required]="true"
          [showIcon]="true"
          icon="pi pi-clock"
          [showButtonBar]="false"
          #from_time="ngModel"
          [class.p-invalid]="
            from_time.invalid && (from_time.dirty || from_time.touched)
          "
        ></p-calendar>
      </div>

      <div class="p-field p-col-8" *ngIf="values.is_catch_up">
        <label for="catch_up_for_date"> Ursprünglicher Termin * </label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="catch_up_for_date"
          name="catch_up_for_date"
          [showButtonBar]="true"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [showIcon]="true"
          [(ngModel)]="values.catch_up_for_date_string"
          dataType="string"
          #catchUpForDate="ngModel"
          [required]="true"
          [class.p-invalid]="
            catchUpForDate.invalid &&
            (catchUpForDate.dirty || catchUpForDate.touched)
          "
        ></p-calendar>
        <small class="color-blue">Für die Erstellung des Folgetermins</small>
      </div>

      <div class="p-field p-col-12">
        <label for="comment">Kommentar</label>
        <textarea
          id="comment"
          name="comment"
          [rows]="2"
          [(ngModel)]="values.comment"
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!tempFileUrl"></i>

    <button
      *ngIf="tempFileUrl"
      (click)="openTempMedia()"
      pButton
      label="Upload Vorschau"
      type="button"
      icon="pi pi-eye"
      iconPos="left"
      class="p-button p-button-info"
    ></button>

    <button
      pButton
      [label]="
        isEdit && hasInvoice ? 'Speichern' : 'Rechnungsentwurf erstellen'
      "
      type="submit"
      (click)="upload()"
      [disabled]="submitted || (tempFileUrl && !clickedOnPreview)"
      [pTooltip]="
        tempFileUrl && !clickedOnPreview ? 'Bitte Upload ansehen' : ''
      "
      [icon]="
        submitted || fileUploading ? 'pi pi-spin pi-spinner' : 'pi pi-check'
      "
    ></button>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
