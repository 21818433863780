import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ConfirmationService } from 'primeng/api'
import { AuthService } from '../../../services/auth.service'
import { ZipcodeSearchResultModel } from '../../../models/search/zipcode-search-result.model'
import { SearchService } from '../../../services/search.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { HelperService } from '../../../services/helper.service'
import { InternEventService } from '../../../services/intern-event.service'
import { InternEventModel } from '../../../models/intern-event/intern-event.model'
import { InternEventTimeModel } from '../../../models/intern-event/intern-event-time.model'

@Component({
  selector: 'app-show-my-intern-event-dialog',
  templateUrl: './show-my-intern-event-dialog.component.html',
})
export class ShowMyInternEventDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null

  public slotTimes = [
    { label: '15 Minuten', value: 15 },
    { label: '30 Minuten', value: 30 },
    { label: '45 Minuten', value: 45 },
    { label: '60 Minuten', value: 60 },
  ]

  public isEdit = false
  public data: any = {}

  public slotIssue = false
  public lecturers = []

  public internEvent = new InternEventModel()
  private originalEventTimes = []

  public submitted = false
  public submittedDelete = false

  constructor(
    private searchService: SearchService,
    public authService: AuthService,
    private ref: DynamicDialogRef,
    private internEventService: InternEventService,
    public config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.isEdit = true

    // this.helperService.dependencies$.subscribe((data: any) => {
    //   this.lecturers = data['event_lecturers']
    // })

    if (this.isEdit) {
      this.internEvent = JSON.parse(JSON.stringify(this.config.data.event))

      // this.internEvent.date = dayjs(this.internEvent.date).format('DD.MM.YYYY')

      // if (!this.internEvent.is_extern_lecturer) {
      //   this.internEvent.lecturer = {
      //     id: this.internEvent.lecturer_id,
      //     type: this.internEvent.lecturer_type,
      //     name:
      //       this.internEvent.lecturer.first_name +
      //       ' ' +
      //       this.internEvent.lecturer.last_name,
      //   }
      // }

      // Wir speichern uns die ursprünglichen Zeiten, damit wir
      // diese bei Änderungen nochmal mit den Anmeldungen befüllen können.
      // this.originalEventTimes = JSON.parse(
      //   JSON.stringify(this.internEvent.times)
      // )
    }

    //this.setTabIndexForIconButtons()
  }

  public checkForTimes(event: any, type: 'from_time' | 'to_time'): void {
    const value = event.target.value

    if (value.length === 1) {
      this.internEvent[type] = `0${value}:00`
    } else if (value.length === 2) {
      this.internEvent[type] = `${value}:00`
    } else if (value.length === 4) {
      this.internEvent[type] = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }
  }

  public createSlots(): void {
    if (this.internEvent.time_type !== 'SLOTS') {
      return
    }

    this.slotIssue = false
    this.internEvent.times = []

    if (
      !this.internEvent.date ||
      !this.internEvent.from_time ||
      !this.internEvent.to_time ||
      !this.internEvent.time_per_slot
    ) {
      return
    }

    const startTime = dayjs(
      `${this.internEvent.date} ${this.internEvent.from_time}`,
      'DD.MM.YYYY HH:mm'
    )
    const endTime = dayjs(
      `${this.internEvent.date} ${this.internEvent.to_time}`,
      'DD.MM.YYYY HH:mm'
    )

    let current = startTime

    while (current.isBefore(endTime)) {
      const endSlot = current.add(this.internEvent.time_per_slot, 'minute')

      if (endSlot.isAfter(endTime)) {
        this.slotIssue = true
      }

      // TODO: Irgendwo hier prüfen, ob die Uhrzeiten in den originalen Daten vorhanden sind

      const internEventTime = new InternEventTimeModel()
      internEventTime.from_time = current.format('HH:mm')
      internEventTime.to_time = endSlot.format('HH:mm')

      this.internEvent.times.push(internEventTime)

      current = current.add(this.internEvent.time_per_slot, 'minute')
    }
  }

  public loadCity(): void {
    this.searchService.findCity(this.internEvent.address_zipcode).subscribe(
      (result: ZipcodeSearchResultModel) => {
        if (result) {
          this.internEvent.address_city = result.city
        }
      },
      () => {
        this.internEvent.address_city = ''
      }
    )
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public timeTypeChanged(): void {
    if (this.internEvent.time_type === 'FIXED') {
      this.slotIssue = false
      this.internEvent.times = [new InternEventTimeModel()]
    }
  }

  private setTabIndexForIconButtons(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-button-icon-only')

      elements.forEach((element) => {
        element.setAttribute('tabindex', '-1')
      })
    }, 200)
  }

  public save(): void {
    if (!this.form.form.valid || this.slotIssue) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.isEdit
      ? this.internEventService.update(this.internEvent)
      : this.internEventService.store(this.internEvent)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.InternEventChanged)
        this.ref.close()
        this.toastService.success(
          'Event gespeichert',
          'Das Event wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Event wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.internEventService.remove(this.internEvent.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.InternEventChanged)
            this.ref.close()
            this.toastService.success(
              'Event gelöscht',
              'Das Event wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Das Dokument konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
