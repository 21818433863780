<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">star</i>
    Meine Events
  </h2>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="data.length > 0">
    <span>Alle Events für mich</span>
  </header>

  <ng-container>
    <div
      class="content-body without-padding auto-height"
      [class.content-loading]="contentLoading"
    >
      <span class="content-no-message with-padding" *ngIf="data.length === 0">
        Keine Events vorhanden
      </span>

      <p-table
        *ngIf="data.length > 0"
        #dt
        [value]="data"
        [globalFilterFields]="['title', 'comment']"
        [rowHover]="true"
        scrollHeight="100%"
        [scrollable]="true"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th pSortableColumn="title">
              Titel <p-sortIcon field="title"></p-sortIcon>
            </th>
            <th style="max-width: 120px" pSortableColumn="date">
              Datum <p-sortIcon field="date"></p-sortIcon>
            </th>
            <th style="max-width: 200px">Uhrzeit</th>
            <th>Bemerkung</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr class="clickable" (click)="openAddInternEventDialog(item)">
            <td>
              {{ item.title }}
            </td>
            <td style="max-width: 120px" >
              {{ item.date | date: "dd.MM.y" }}
            </td>
            <td style="max-width: 200px">
              <div style="display: flex; flex-direction: column">
                <span
                  style="display: flex; align-items: center; gap: 5px"
                  *ngFor="let time of item.times"
                >
                  {{ time.from_time }} - {{ time.to_time }}

                  <small class="color-gray"
                    >({{ time.bookings.length }} /
                    {{ time.max_participants }})</small
                  >
                </span>
              </div>
            </td>
            <td>
              {{ item.comment || '-' }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
