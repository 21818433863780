import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { TrainingService } from '../../../services/training.service'
import { ConfirmationService } from 'primeng/api'
import { CaregiverService } from '../../../services/caregivers.service'
import { CaregiverDateService } from '../../../services/caregiver-date.service'
import { HelperService } from '../../../services/helper.service'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'
import { CaregiverTimeService } from '../../../services/caregiver-time.service'
import { CaregiverTimeModel } from '../../../models/caregiver/caregiver-time.model'
import { SearchService } from '../../../services/search.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { InternEventService } from '../../../services/intern-event.service'
import { InternEventModel } from '../../../models/intern-event/intern-event.model'
import { InternEventTimeModel } from '../../../models/intern-event/intern-event-time.model'

@Component({
  selector: 'app-add-user-caregiver-intern-event-dialog',
  templateUrl: './add-user-caregiver-intern-event-dialog.component.html',
})
export class AddUserCaregiverInternEventDialogComponent
  implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public appointments: any[] = []

  public originalTimeId = 0
  public currentTime: CaregiverTimeModel = new CaregiverTimeModel()

  public isLoading = true
  public internEvents: InternEventModel[] = []

  public caregivers: CaregiverDetailModel[] = []
  public data: any = {}
  public values = {
    selected_event: null as InternEventModel | null,
    selected_time: null as InternEventTimeModel | null,
  }
  public submitted = false
  public hasVacation = false
  public alreadyFull = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private searchService: SearchService,
    private helperService: HelperService,
    private internEventService: InternEventService,
    private caregiverTimeService: CaregiverTimeService,
    private trainingService: TrainingService,
    private caregiverDateService: CaregiverDateService,
    private userCaregiverService: UserCaregiverService,
    private eventbus: EventBusService,
    public caregiverService: CaregiverService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)

    this.data = this.config.data

    this.loadEvents()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public selectTime(time: InternEventTimeModel): void {
    if ((!time.is_full || this.originalTimeId === time.id) && time.is_active) {
      this.values.selected_time = time
    }
  }

  public dateTypeChanged(): void {
    this.values.selected_time = null
    this.alreadyFull = false

    if (this.values.selected_event?.time_type === 'FIXED') {
      this.values.selected_time = this.values.selected_event.times[0]

      // Wir prüfen, ob das Event nichts bereits ausgebucht ist.
      if (this.values.selected_event?.times[0].is_full) {
        this.alreadyFull = true
      }
    }

    // Falls der Benutzer einen Zeitslot bereits gebucht hat.
    if (
      this.values.selected_event?.time_type === 'SLOTS' &&
      this.values.selected_event.time
    ) {
      this.originalTimeId = this.values.selected_event.time.id
      this.values.selected_time = this.values.selected_event.time
    }

    this.markAsRead()
  }

  private markAsRead(): void {
    if (this.values.selected_event) {
      this.internEventService
        .markAsReadForCaregiver(this.values.selected_event.id)
        .subscribe()
    }
  }

  private loadEvents(): void {
    this.internEventService
      .allEventsForCaregivers()
      .subscribe((response: InternEventModel[]) => {
        this.internEvents = response

        setTimeout(() => {
          this.isLoading = false
        }, 200)
      })
  }

  public removeFromBooking(): void {
    if (!this.values.selected_event) {
      alert('Bitte wählen Sie ein Event aus')
      return
    }

    this.submitted = true

    this.internEventService
      .removeFromBooking(this.values.selected_event.id)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.InternEventChanged)
          this.toastService.success(
            'Event abgemeldet',
            'Die Abmeldung wurde erfolgreich gespeichert'
          )

          this.ref.close()
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }

  public upload(): void {
    this.hasVacation = false
    this.alreadyFull = false

    if (!this.values.selected_time || !this.values.selected_event) {
      alert('Bitte wählen Sie ein Event und eine Uhrzeit')
      return
    }

    // Falls der Benutzer bei einem Fixen Event bereits angemeldet ist,
    // soll er diesen nicht nochmal abspeichern können.
    if (
      this.values.selected_event.time &&
      this.values.selected_event.time_type === 'FIXED'
    ) {
      alert('Sie sind bereits angemeldet für dieses Event')
      return
    }

    this.submitted = true

    this.internEventService
      .book(this.values.selected_event.id, this.values.selected_time.id)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.InternEventChanged)
          this.toastService.success(
            'Event gespeichert',
            'Die Anmeldung wurde erfolgreich gespeichert'
          )

          this.ref.close()
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else if (error.status === StatusCodes.CONFLICT) {
            this.alreadyFull = true

            const timeId = this.values.selected_time?.id

            this.values.selected_time = null

            this.values.selected_event?.times.forEach(
              (time: InternEventTimeModel) => {
                if (time.id === timeId) {
                  time.is_full = true
                }
              }
            )
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }
}
