<form #form="ngForm" class="dialog-form">
  <!--  <div-->
  <!--    *ngIf="!fromNearPatient"-->
  <!--    class="dialog-sidebar google-maps-sidebar without-padding bigger-dialog-sidebar"-->
  <!--  >-->
  <!--    <google-map-->
  <!--      *ngIf="patient"-->
  <!--      [center]="center"-->
  <!--      [options]="options"-->
  <!--      width="100%"-->
  <!--    >-->
  <!--      <map-marker-->
  <!--        [position]="center"-->
  <!--        [label]="patient.full_name"-->
  <!--        [title]="'Test Titel'"-->
  <!--        [options]="optionsMarker"-->
  <!--      >-->
  <!--      </map-marker>-->
  <!--    </google-map>-->

  <!--    <span-->
  <!--      style="display: flex; flex-direction: column; gap: 10px"-->
  <!--      *ngIf="!patient"-->
  <!--      class="content-no-message color-white"-->
  <!--    >-->
  <!--      <i class="pi pi-map-marker" style="font-size: 42px; opacity: 0.6"></i>-->
  <!--      Bitte einen Patienten auswählen</span-->
  <!--    >-->
  <!--  </div>-->

  <div
    class="dialog-sidebar bigger-dialog-sidebar secondary-dialog-sidebar without-padding"
    style="justify-content: space-between"
  >
    <div style="padding: 20px">
      <span class="content-sub-headline"
        >Einsätze des Mitarbeiters an diesem Tag</span
      >

      <small
        class="color-gray"
        *ngIf="!values.home_visit_date_string || !values.home_visit_from"
        >Bitte Datum und Mitarbeiter auswählen</small
      >

      <small
        class="color-gray"
        *ngIf="
          values.home_visit_date_string &&
          values.home_visit_from &&
          appointmentsForUser.length === 0
        "
        >Keine Einsätze vorhanden</small
      >

      <div class="p-field p-col-12" style="margin: 0">
        <small
          style="display: flex; flex-direction: column; margin: 0"
          *ngFor="let appointment of appointmentsForUser; let i = index"
        >
          {{ appointment.date }}
          {{ appointment.name }} ({{ appointment.type }})</small
        >
      </div>
    </div>

    <!--    <div style="padding: 20px" *ngIf="!fromNearPatient">-->
    <!--      <span class="content-sub-headline"-->
    <!--        >Offene Beratungseinsätze in der Nähe</span-->
    <!--      >-->
    <!--      <small-->
    <!--        class="color-gray"-->
    <!--        *ngIf="!values.home_visit_date_string || !patient"-->
    <!--        >Bitte Datum und Patient auswählen</small-->
    <!--      >-->

    <!--      <small-->
    <!--        class="color-gray"-->
    <!--        *ngIf="-->
    <!--          values.home_visit_date_string &&-->
    <!--          patient &&-->
    <!--          appointmentsForOtherPatients.length === 0-->
    <!--        "-->
    <!--        >Keine anderen Patienten vorhanden</small-->
    <!--      >-->
    <!--    </div>-->

    <!--    <div *ngIf="appointmentsForOtherPatients.length && !fromNearPatient">-->
    <!--      <p-table-->
    <!--        #dt-->
    <!--        [value]="appointmentsForOtherPatients"-->
    <!--        [rowHover]="true"-->
    <!--        scrollHeight="100%"-->
    <!--        [scrollable]="true"-->
    <!--      >-->
    <!--        <ng-template pTemplate="header">-->
    <!--          <tr>-->
    <!--            <th style="max-width: 50px"><i class="pi pi-map-marker"></i></th>-->
    <!--            <th>Patient</th>-->
    <!--            <th pSortableColumn="distance_sortable">-->
    <!--              Umkreis-->
    <!--              <p-sortIcon field="distance_sortable"></p-sortIcon>-->
    <!--            </th>-->
    <!--            <th pSortableColumn="last_consulting.date">-->
    <!--              Letzter-->
    <!--              <p-sortIcon field="last_consulting.date"></p-sortIcon>-->
    <!--            </th>-->
    <!--            <th pSortableColumn="next_consulting.date">-->
    <!--              Nächster <p-sortIcon field="next_consulting.date"></p-sortIcon>-->
    <!--            </th>-->
    <!--            <th style="max-width: 50px"></th>-->
    <!--          </tr>-->
    <!--        </ng-template>-->
    <!--        <ng-template pTemplate="body" let-consulting>-->
    <!--          <tr>-->
    <!--            <td style="max-width: 50px">-->
    <!--              <p-checkbox-->
    <!--                [value]="consulting"-->
    <!--                [(ngModel)]="selectedNearPatients"-->
    <!--              ></p-checkbox>-->
    <!--            </td>-->
    <!--            <td>-->
    <!--              {{ consulting.patient.first_name }}-->
    <!--              {{ consulting.patient.last_name }}-->
    <!--            </td>-->
    <!--            <td>{{ consulting.distance }} KM</td>-->
    <!--            <td>-->
    <!--              <div style="display: flex; align-items: center; gap: 5px">-->
    <!--                {{ consulting.last_consulting?.date | date: "dd.MM.y" }}-->
    <!--                <i-->
    <!--                  class="pi pi-info-circle color-gray"-->
    <!--                  style="font-size: 13px"-->
    <!--                  *ngIf="consulting.last_consulting"-->
    <!--                  [pTooltip]="consulting.last_consulting.for_user"-->
    <!--                ></i>-->
    <!--              </div>-->
    <!--            </td>-->
    <!--            <td>-->
    <!--              {{ consulting.next_consulting.date | date: "dd.MM.y" }}-->
    <!--            </td>-->
    <!--            <td class="table-action" style="max-width: 50px">-->
    <!--              <i-->
    <!--                (click)="openConsultingDialog(consulting)"-->
    <!--                class="pi pi-plus"-->
    <!--                pTooltip="Termin erstellen"-->
    <!--              ></i>-->
    <!--            </td>-->
    <!--          </tr>-->
    <!--        </ng-template>-->
    <!--      </p-table>-->
    <!--    </div>-->
  </div>

  <div class="dialog-form-body">
    <div
      class="detail-information-banner warning-version"
      *ngIf="recommendationIssue.has_issue"
    >
      <span
        >Der Kunde wurde von {{ recommendationIssue.name }} empfohlen. Es kann
        trotzdem ein Beratungseinsatz erstellt werden.</span
      >
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" style="padding: 0">
      <div class="p-field p-col-12" *ngIf="fromNearPatient || !withoutPatient">
        <label for="patient">Patient </label>
        <strong>{{ patient.full_name }}</strong>
      </div>

      <div class="p-field p-col-12" *ngIf="withoutPatient && !fromNearPatient">
        <label for="patient">Patient * </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          scrollHeight="350px"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="patient"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
          (onSelect)="patientSelected($event)"
          [required]="true"
          [autofocus]="true"
          #patientRef="ngModel"
          [class.p-invalid]="
            patientRef.invalid && (patientRef.dirty || patientRef.touched)
          "
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <!--                <span class="search-result-id">#{{ patient.id }}</span>-->
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="p-field p-col-8">
        <label
          for="qm-home-visit-date"
          style="display: flex; justify-content: space-between"
        >
          Datum *

          <div class="p-formgroup-inline">
            <div class="p-field-checkbox" style="margin: 0 !important">
              <p-checkbox
                label="Ist ein Nachholtermin"
                name="is_catch_up"
                [binary]="true"
                [(ngModel)]="values.is_catch_up"
              ></p-checkbox>
            </div>
          </div>
        </label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          (onSelect)="loadUserAppointments()"
          (onBlur)="loadUserAppointments()"
          inputId="qm-home-visit-date"
          name="qm-home-visit-date"
          [showButtonBar]="true"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [showIcon]="true"
          [(ngModel)]="values.home_visit_date_string"
          dataType="string"
          #date="ngModel"
          [required]="true"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-4">
        <label for="from_time">Uhrzeit *</label>
        <p-calendar
          (onBlur)="checkForTimes($event)"
          inputId="from_time"
          name="from_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.home_visit_time"
          dataType="string"
          [required]="true"
          [showIcon]="true"
          icon="pi pi-clock"
          [showButtonBar]="false"
          #from_time="ngModel"
          [class.p-invalid]="
            from_time.invalid && (from_time.dirty || from_time.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.is_catch_up"
      style="padding: 0"
    >
      <div class="p-field p-col-8">
        <label for="catch_up_for_date"> Ursprünglicher Termin * </label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="catch_up_for_date"
          name="catch_up_for_date"
          [showButtonBar]="true"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [showIcon]="true"
          [(ngModel)]="values.catch_up_for_date_string"
          dataType="string"
          #catchUpForDate="ngModel"
          [required]="true"
          [class.p-invalid]="
            catchUpForDate.invalid &&
            (catchUpForDate.dirty || catchUpForDate.touched)
          "
        ></p-calendar>
        <small class="color-blue">Für die Erstellung des Folgetermins</small>
      </div>
    </div>

    <div class="p-field">
      <label for="home_visit_from">Mitarbeiter</label>
      <p-dropdown
        name="home_visit_from"
        inputId="home_visit_from"
        placeholder="Bitte auswählen"
        optionLabel="full_name"
        optionValue="full_name"
        (onChange)="loadUserAppointments()"
        [(ngModel)]="values.home_visit_from"
        [options]="homeVisitFrom"
      ></p-dropdown>
    </div>

    <div class="p-field">
      <label for="comment">Kommentar</label>
      <textarea
        id="comment"
        name="comment"
        [rows]="2"
        [(ngModel)]="values.comment"
        pInputTextarea
      ></textarea>
    </div>

    <div class="p-field" *ngIf="!isPreview">
      <label>Übergabe per</label>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="E-Mail"
            name="sent_type"
            (onClick)="buildReceiverOptions()"
            value="MAIL"
            [(ngModel)]="values.sent_type"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Post"
            (onClick)="buildReceiverOptions()"
            name="sent_type"
            value="POST"
            [(ngModel)]="values.sent_type"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Telefonisch"
            name="sent_type"
            (onClick)="buildReceiverOptions()"
            value="PHONE"
            [(ngModel)]="values.sent_type"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Persönlich"
            (onClick)="buildReceiverOptions()"
            name="sent_type"
            value="PERSONAL"
            [(ngModel)]="values.sent_type"
          ></p-radioButton>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="values.sent_type === 'MAIL'">
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox">
          <p-checkbox
            label="E-Mail senden"
            name="send_email"
            [binary]="true"
            [(ngModel)]="values.send_email"
          ></p-checkbox>
        </div>
        <small
          class="color-blue"
          *ngIf="consulting?.status === 'OPEN' || config.data.isNew"
          >Wird nur bei der Terminierung versendet. Nicht beim normalen
          speichern.</small
        >
      </div>
    </div>

    <!-- Empfänger -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      style="padding: 0"
      *ngIf="values.sent_type"
    >
      <div class="p-field p-col-6">
        <label for="receiver">Empfänger *</label>
        <p-dropdown
          inputId="receiver"
          name="receiver"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver"
          appendTo="body"
          scrollHeight="400px"
          [required]="true"
          #receiver="ngModel"
          [class.p-invalid]="
            receiver.invalid && (receiver.dirty || receiver.touched)
          "
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
            <span *ngIf="values.sent_type === 'MAIL'">{{
              receiver.email
            }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- CC an -->
      <div
        class="p-field p-col-6"
        *ngIf="values.send_email && values.sent_type === 'MAIL'"
      >
        <label for="cc">CC an</label>
        <p-multiSelect
          inputId="cc"
          placeholder="Optional"
          [options]="ccOptions"
          [filter]="false"
          [showHeader]="false"
          name="cc"
          appendTo="body"
          scrollHeight="400px"
          [(ngModel)]="values.cc"
        ></p-multiSelect>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i
      *ngIf="!config.data.isNew && !hasMedia"
      class="pi pi-trash"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird storniert' : 'BE stornieren'"
      (click)="remove($event)"
    ></i>

    <i *ngIf="!config.data.isNew && hasMedia"></i>

    <i
      *ngIf="
        config.data.isNew && !values.send_email && values.sent_type !== 'POST'
      "
    ></i>

    <a
      *ngIf="values.send_email && values.sent_type === 'MAIL'"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="consultingService.getMailPreviewLink(values)"
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <a
      *ngIf="values.sent_type === 'POST'"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="consultingService.getPdfPreviewLink(values)"
    >
      <button
        pButton
        label="PDF Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <!--    <button-->
    <!--      *ngIf="isPreview"-->
    <!--      pButton-->
    <!--      label="Daten speichern"-->
    <!--      type="submit"-->
    <!--      (click)="savePreview()"-->
    <!--      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"-->
    <!--    ></button>-->

    <div class="dialog-footer-action-container">
      <button
        *ngIf="
          !isPreview && (consulting?.status === 'OPEN' || config.data.isNew)
        "
        pButton
        label="Speichern ohne Terminierung"
        type="submit"
        class="p-button"
        (click)="saveAsOpen()"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <button
        *ngIf="!isPreview"
        pButton
        [label]="
          consulting?.status !== 'OPEN' && !config.data.isNew
            ? 'Speichern'
            : 'Terminieren'
        "
        type="submit"
        class="p-button p-button-info"
        (click)="save()"
        [disabled]="submitted || (!clickedOnPreview && values.send_email)"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
