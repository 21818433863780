<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Meine letzten 1000 gelesene Mitteilungen</span>

    <div class="content-header-buttons">
      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div class="content-body" *ngIf="notifications.length == 0">
    <span class="content-no-message">Keine Mitteilungen vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="notifications.length > 0"
  >
    <p-table
      #dtOpen
      [value]="notifications"
      [rowHover]="true"
      [autoLayout]="true"
      [paginator]="true"
      [rows]="50"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Mitteilungen"
      scrollHeight="100%"
      [scrollable]="true"
      [globalFilterFields]="['notifiable.title', 'text']"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 400px; min-width: 100px">Todo</th>
          <th style="max-width: 1200px; min-width: 100px">Text</th>
          <th style="max-width: 160px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="max-width: 50px">von</th>
          <th style="max-width: 160px" pSortableColumn="seen_at">
            Gelesen am <p-sortIcon field="seen_at"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-notification>
        <tr
          class="clickable"
          (click)="openShowTodoDialog(notification.notifiable)"
        >
          <td style="max-width: 400px; min-width: 100px">
            <div style="display: flex; flex-direction: column; max-width: 100%">
              <span class="history-information">
                <p class="history-text text-nowrap">
                  {{ notification.notifiable.title }}
                </p>
              </span>
            </div>
          </td>
          <td style="max-width: 1200px; min-width: 100px">
            <div style="display: flex; flex-direction: column; max-width: 100%">
              <span class="history-information">
                <p class="history-text text-nowrap">
                  {{ notification.text }}
                </p>
              </span>
            </div>
          </td>
          <td style="max-width: 160px" class="color-gray">
            {{ notification.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td style="max-width: 50px">
            {{
              notification.created_from_user?.user_system?.short_name ||
                "Unbekannt"
            }}
          </td>
          <td style="max-width: 160px" class="color-gray">
            {{ notification.seen_at | date: "dd.MM.y, H:mm" }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
