<app-no-permission *ngIf="!hasPermission"></app-no-permission>

<div *ngIf="userSystemDetail.notice && hasPermission" class="notice-wrap">
  <i class="pi pi-paperclip notice-wrap-drag-handle notice-icon"></i>
  <div (click)="openNoticeDialog()" class="notice-text">
    {{ userSystemDetail.notice }}
  </div>
</div>

<div class="content-actions" *ngIf="hasPermission">
  <h2>
    {{ userSystemDetail.form_of_address }} {{ userSystemDetail.first_name }}
    {{ userSystemDetail.last_name }}

    <i
      class="pi pi-key color-main1"
      *ngIf="userSystemDetail.user.super_admin"
      pTooltip="SUPER ADMIN"
    ></i>

    <i
      *ngIf="userSystemDetail.user.deleted_at"
      class="p-tag p-tag-danger p-tag-rounded"
      >Deaktiviert</i
    >
  </h2>

  <a
    routerLink="/administration/users/{{ userSystemDetail.id }}/edit"
    *ngIf="userSystemDetail.id"
  >
    <button
      pButton
      label="Bearbeiten"
      class="p-button-text"
      type="button"
      icon="pi pi-pencil"
      iconPos="right"
    ></button>
  </a>
</div>

<header
  class="content-header-tabs"
  *ngIf="hasPermission"
  [class.disabled]="!userSystemDetail.id"
>
  <a
    routerLink="/administration/users/{{ userSystemDetail.id }}"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Allgemeines</a
  >
  <a
    routerLink="/administration/users/{{ userSystemDetail.id }}/history"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Historie</a
  >
</header>

<ng-container *ngIf="hasPermission">
  <router-outlet></router-outlet>
</ng-container>
