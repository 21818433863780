<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar white-sidebar space-between">
    <!-- Teilnehmer -->
    <div *ngIf="isEdit" class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <span class="content-sub-headline">Teilnehmer</span>
        <span class="color-gray" *ngIf="!internEvent.all_caregiver_names"
          >Noch keine Teilnehmer vorhanden</span
        >

        <span
          style="white-space: pre-wrap"
          *ngIf="internEvent.all_caregiver_names"
          >{{ internEvent.all_caregiver_names }}</span
        >
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label>Titel</label>
        <strong>{{ internEvent.title }}</strong>
      </div>

      <div class="p-field p-col-12">
        <label>Datum</label>
        <strong>{{ internEvent.date | date: "dd.MM.y" }}</strong>
      </div>

      <div class="p-field p-col-12" *ngIf="internEvent.time_type === 'FIXED'">
        <label>Uhrzeit</label>
        <strong
          >{{ internEvent.from_time }} - {{ internEvent.to_time }} Uhr</strong
        >
      </div>

      <div class="p-field p-col-12">
        <label>Adresse</label>
        <strong>{{ internEvent.address }}</strong>
      </div>

      <div class="p-field p-col-12" *ngIf="internEvent.time_type === 'FIXED'">
        <label>Max. Teilnehmer</label>
        <strong>{{ internEvent.times[0].max_participants }}</strong>
      </div>

      <div class="p-field p-col-12">
        <label>Zählt als Arbeitszeit?</label>
        <strong>{{ internEvent.is_working_time ? "Ja" : "Nein" }}</strong>
      </div>

      <div class="p-field p-col-12">
        <label>Bemerkung</label>
        <strong>{{ internEvent.comment || "-" }}</strong>
      </div>

      <div class="p-field p-col-12" *ngIf="internEvent.time_type === 'SLOTS'">
        <label>Zeitslots</label>
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="
          internEvent.times.length > 0 && internEvent.time_type === 'SLOTS'
        "
      >
        <p-table #dt [value]="internEvent.times" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>Aktiv</th>
              <th>Uhrzeit</th>
              <th style="text-align: right">Max. Teilnehmer</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-time>
            <tr>
              <td>
                {{ time.is_active ? "AKTIV" : "NICHT AKTIV" }}
              </td>
              <td>{{ time.from_time }} - {{ time.to_time }}</td>
              <td style="text-align: right">
                {{ time.max_participants }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <!--  <div class="dialog-form-footer">-->
  <!--    <i *ngIf="!isEdit"></i>-->

  <!--    <div style="display: flex; gap: 20px">-->
  <!--      <i-->
  <!--        class="pi pi-trash"-->
  <!--        *ngIf="isEdit"-->
  <!--        [class.pi-trash]="!submittedDelete"-->
  <!--        [class.pi-spin]="submittedDelete"-->
  <!--        [class.pi-spinner]="submittedDelete"-->
  <!--        tooltipPosition="left"-->
  <!--        tooltipZIndex="20000"-->
  <!--        [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Event löschen'"-->
  <!--        (click)="remove($event)"-->
  <!--      ></i>-->
  <!--    </div>-->

  <!--    <button-->
  <!--      (click)="save()"-->
  <!--      pButton-->
  <!--      [label]="isEdit ? 'Speichern' : 'Speichern'"-->
  <!--      type="button"-->
  <!--      [disabled]="submitted || slotIssue"-->
  <!--      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"-->
  <!--    ></button>-->
  <!--  </div>-->
</form>

<p-confirmPopup></p-confirmPopup>
