<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2 class="bigger-headline">Herzlich Willkommen!</h2>
</div>

<div class="dashboard-infos">
  <div class="dashboard-info">
    <label>Patient</label>
    <strong>Frau Beate Becker</strong>
  </div>

  <div class="dashboard-info">
    <label>Geburtstag</label>
    <strong>12.02.1961</strong>
  </div>

  <div class="dashboard-info">
    <label>Ort</label>
    <strong>66113 Saarbrücken</strong>
  </div>

  <div class="dashboard-info-buttons">
    <a routerLink="/kunde-werben">
      <button
        pButton
        label="Kunde werben"
        class="p-button p-button-info"
        type="button"
        icon="pi pi-user-plus"
        iconPos="left"
      ></button>
    </a>

    <button
      (click)="openUserCustomerRequestDialog()"
      pButton
      label="Neue Anfrage"
      class="p-button"
      type="button"
      icon="pi pi-star"
      iconPos="left"
    ></button>
  </div>
</div>

<div class="dashboard-grid-system">
  <div class="dashboard-grid">
    <div class="dashboard-grid-item">
      <div class="content new-content-container">
        <header class="content-header">
          <h2>
            <i class="tio">calendar_note</i>
            Nächste Termine
            <p-badge severity="info" value="NEUE TERMINE"></p-badge>
          </h2>
          <a routerLink="/meine-termine">
            alle Termine <i class="pi pi-arrow-right"></i>
          </a>
        </header>

        <div class="content-body content-base-height-customer without-padding">
          <p-table
            scrollHeight="100%"
            [scrollable]="true"
            [value]="[1, 2]"
            [rowHover]="true"
            responsiveLayout="scroll"
          >
            <ng-template pTemplate="header">
              <tr class="table-headline-highlight">
                <th>Datum</th>
                <th>Uhrzeit</th>
                <th>Betreuungskraft</th>
                <!--                <th style="max-width: 50px"></th>-->
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>11.04.2025 (Do)</td>
                <td>12:00 - 14:30 (2,5 Std)</td>
                <td>Fr. Sarah Bick</td>
                <!--                <td class="table-action" style="max-width: 50px">-->
                <!--                  <i-->
                <!--                    class="pi pi-pencil show-always"-->
                <!--                    pTooltip="Bearbeiten"-->
                <!--                    tooltipPosition="left"-->
                <!--                  ></i>-->
                <!--                </td>-->
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div class="dashboard-grid-item">
      <div class="content new-content-container">
        <header class="content-header">
          <h2><i class="tio">time</i> Letzte Termine</h2>
          <a routerLink="/meine-termine">
            alle Termine <i class="pi pi-arrow-right"></i>
          </a>
        </header>

        <div class="content-body content-base-height-customer without-padding">
          <p-table
            [value]="[1, 2, 3]"
            [rowHover]="true"
            scrollHeight="100%"
            [scrollable]="true"
            responsiveLayout="scroll"
          >
            <ng-template pTemplate="header">
              <tr class="table-headline-highlight">
                <th>Datum</th>
                <th>Uhrzeit</th>
                <th>Betreuungskraft</th>
                <th style="max-width: 50px"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>01.04.2025</td>
                <td>11:00 - 13:00</td>
                <td>Fr. Sarah Bick</td>
                <td class="table-action" style="max-width: 50px">
                  <i
                    class="pi pi-star show-always"
                    pTooltip="Feedback geben"
                    tooltipPosition="left"
                  ></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

  <div class="dashboard-grid">
    <div class="dashboard-grid-item">
      <div class="content new-content-container">
        <header class="content-header">
          <h2><i class="tio">euro_outlined</i> Rechnungen</h2>
          <a routerLink="/">
            alle Rechnungen <i class="pi pi-arrow-right"></i>
          </a>
        </header>

        <div class="content-body content-base-height-customer without-padding">
          <p-table
            scrollHeight="100%"
            [scrollable]="true"
            [value]="[1, 2]"
            [rowHover]="true"
            responsiveLayout="scroll"
          >
            <ng-template pTemplate="header">
              <tr
                class="table-headline-highlight table-headline-highlight-light"
              >
                <th>Nummer</th>
                <th>Leistungsmonat</th>
                <th>Gesamtkosten</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>2024-12-0988</td>
                <td>November 2024</td>
                <td>196,00 €</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="dashboard-grid-item">
      <div class="content new-content-container">
        <header class="content-header">
          <h2><i class="tio">star_outlined</i> Offene Anfragen</h2>
          <a routerLink="/meine-anfragen">
            alle Anfragen <i class="pi pi-arrow-right"></i>
          </a>
        </header>

        <div class="content-body content-base-height-customer without-padding">
          <p-table
            scrollHeight="100%"
            [scrollable]="true"
            [value]="[1]"
            [rowHover]="true"
            responsiveLayout="scroll"
          >
            <ng-template pTemplate="header">
              <tr
                class="table-headline-highlight table-headline-highlight-light"
              >
                <th>Typ</th>
                <th>Datum</th>
                <th style="max-width: 50px"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>Urlaub / Terminsperre</td>
                <td>09.01.2025 - 15.01.2025</td>
                <td class="table-action" style="max-width: 50px">
                  <i
                    class="pi pi-eye show-always"
                    pTooltip="Ansehen"
                    tooltipPosition="left"
                  ></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
