import {InternEventTimeBookingModel} from "./intern-event-time-booking.model";

export class InternEventTimeModel {
  id = 0
  max_participants = 1
  is_active = true
  intern_event_id = 0
  from_time = ''
  to_time = ''
  is_full = false
  date: Date | null = null
  date_from: Date | null = null
  date_to: Date | null = null
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null

  bookings: InternEventTimeBookingModel[] = []
}
