<div class="content-actions">
  <i class="tio icon-large">star</i>

  <h2>
    <i class="tio">star</i>
    Events
  </h2>
</div>

<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" style="margin: 0" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Events</span>

    <div class="content-header-buttons">
      <button
        (click)="openAddInternEventDialog()"
        pButton
        label="Neues Event"
        type="button"
        icon="pi pi-plus"
        iconPos="left"
        class="p-button-outlined"
      ></button>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="internEvents.length == 0">
    <span class="content-no-message">Keine Events vorhanden</span>
  </div>

  <div class="content-body without-padding" *ngIf="internEvents.length > 0">
    <p-table
      #dt
      [value]="internEvents"
      [globalFilterFields]="['title', 'comment']"
      [rowHover]="true"
      scrollHeight="100%"
      [scrollable]="true"
      [(first)]="firstPage"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="title">
            Titel <p-sortIcon field="title"></p-sortIcon>
          </th>
          <th style="max-width: 120px" pSortableColumn="date">
            Datum <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th style="max-width: 200px">Uhrzeit</th>
          <th>Referent</th>
          <th>Bemerkung</th>
          <th style="max-width: 100px" pSortableColumn="is_working_time">
            AZ <p-sortIcon field="is_working_time"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable" (click)="openAddInternEventDialog(item)">
          <td>
            {{ item.title }}
          </td>
          <td style="max-width: 120px">
            {{ item.date | date: "dd.MM.y" }}
          </td>
          <td style="max-width: 200px">
            <div style="display: flex; flex-direction: column">
              <span
                style="display: flex; align-items: center; gap: 5px"
                *ngFor="let time of item.times"
              >
                {{ time.from_time }} - {{ time.to_time }}

                <small class="color-gray"
                  >({{ time.bookings.length }} /
                  {{ time.max_participants }})</small
                >
              </span>
            </div>
          </td>
          <td>
            <ng-container *ngIf="!item.is_extern_lecturer">
              {{ item.lecturer.first_name }} {{ item.lecturer.last_name }}
            </ng-container>
            <ng-container *ngIf="item.is_extern_lecturer">
              {{ item.extern_lecturer }} (Extern)
            </ng-container>
          </td>
          <td>
            {{ item.comment || '-' }}
          </td>
          <td style="max-width: 100px">
            <i class="pi pi-check color-main1" *ngIf="item.is_working_time"></i>
            <i
              class="pi pi-times color-alert"
              *ngIf="!item.is_working_time"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
