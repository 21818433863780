import { UserModel } from './user.model'

export class UserSystemFormModel {
  id: number | null = null
  form_of_address = ''
  first_name = ''
  last_name = ''
  birthday_date: Date | null | string = null
  birthday_date_string: string | null = null
  phone_1 = ''
  phone_2 = ''
  notice = ''
  phone_identifier = ''
  signature = ''
  for_consulting = false
  has_individual_permissions = false
  as_supervisor = false
  for_lecturing = false
  for_thc = false
  user_id: number | null = null
  user: UserModel = new UserModel()
  created_at: Date | null = null
  updated_at: Date | null = null

  role_names = ''
  role_ids = [] as number[]
  individual_permission_names = ''
  individual_permission_ids = [] as number[]
}
