<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <i class="tio icon-large">call_talking</i>

  <h2>
    <i class="tio">call_talking</i>
    Telefonate
  </h2>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span>Aktive Telefonate</span>
  </header>

  <div class="content-body" *ngIf="phoneCalls.active.length == 0">
    <span class="content-no-message">Keine aktiven Telefonate vorhanden</span>
  </div>

  <div
    class="content-body auto-height without-padding"
    *ngIf="phoneCalls.active.length > 0"
  >
    <p-table [value]="phoneCalls.active" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 120px" pSortableColumn="we_called">
            Anruf <p-sortIcon field="we_called"></p-sortIcon>
          </th>
          <th pSortableColumn="number">
            Nummer <p-sortIcon field="number"></p-sortIcon>
          </th>
          <th pSortableColumn="phone_caller_name">
            Name <p-sortIcon field="phone_caller_name"></p-sortIcon>
          </th>
          <th pSortableColumn="created_at">
            Anruf um <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th pSortableColumn="user_system.first_name">
            Benutzer <p-sortIcon field="user_system.first_name"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-call>
        <tr>
          <td>
            <span class="color-gray" *ngIf="!call.we_called">
              <i class="pi pi-arrow-right" style="font-size: 12px"></i>
              an uns
            </span>

            <span class="color-gray" *ngIf="call.we_called">
              von uns
              <i class="pi pi-arrow-right" style="font-size: 12px"></i>
            </span>
          </td>

          <td *ngIf="call.number">
            <a
              href="tel:{{ call.number }}"
              (click)="phoneCallService.createCalling(call.number)"
              >{{ call.number }}</a
            >
          </td>
          <td *ngIf="!call.number">Unbekannt</td>

          <td>{{ call.phone_caller_name || "Unbekannt" }}</td>
          <td>{{ call.created_at | date: "H:mm" }} </td>
          <td>
            {{ call.user_system?.first_name }} {{ call.user_system?.last_name }}
          </td>
          <td class="table-action">
            <i
              *ngIf="authService.isSuperAdmin()"
              class="pi pi-trash"
              pTooltip="Löschen"
              tooltipPosition="left"
              (click)="deleteActiveCall(call)"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span
      >Abgeschlossene Telefonate
      <i class="pi pi-info-circle color-gray" pTooltip="Die letzten 1000"></i
    ></span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="phoneCalls.finished.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div class="content-body" *ngIf="phoneCalls.finished.length == 0">
    <span class="content-no-message">Keine Telefonate vorhanden</span>
  </div>

  <div
    class="content-body without-padding"
    *ngIf="phoneCalls.finished.length > 0"
  >
    <p-table
      #dt
      [value]="phoneCalls.finished"
      [globalFilterFields]="[
        'id',
        'number',
        'phone_caller_name',
        'phone_caller_id',
        'user_system.first_name',
        'user_system.last_name'
      ]"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="state-phone-calls-2"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 120px"></th>
          <th style="width: 120px" pSortableColumn="we_called">
            Anruf <p-sortIcon field="we_called"></p-sortIcon>
          </th>
          <th pSortableColumn="number">
            Nummer <p-sortIcon field="number"></p-sortIcon>
          </th>
          <th pSortableColumn="phone_caller_name">
            Name <p-sortIcon field="phone_caller_name"></p-sortIcon>
          </th>
          <th pSortableColumn="created_at">
            Anruf am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
          <th
            pSortableColumn="history_appointment_transfer"
            style="width: 60px"
          >
            <i
              pTooltip="Terminübergabe"
              class="pi pi-calendar"
              style="font-size: 14px"
            ></i>
            <p-sortIcon field="history_appointment_transfer"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
          <th style="width: 120px" pSortableColumn="duration">
            Dauer <p-sortIcon field="duration"></p-sortIcon>
          </th>
          <th pSortableColumn="user_system.first_name">
            Benutzer <p-sortIcon field="user_system.first_name"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-call>
        <tr>
          <td>
            <div class="text-center-container">
              <span *ngIf="call.is_consulting" class="p-tag p-tag-rounded"
                >NK-Call</span
              >
              <i
                *ngIf="call.strong_interest"
                class="pi pi-info-circle color-blue"
                style="font-size: 14px"
                pTooltip="Starkes Interesse"
              ></i>
            </div>
          </td>
          <td>
            <span class="color-gray" *ngIf="!call.we_called">
              <i class="pi pi-arrow-right" style="font-size: 12px"></i>
              an uns
            </span>

            <span class="color-gray" *ngIf="call.we_called">
              von uns
              <i class="pi pi-arrow-right" style="font-size: 12px"></i>
            </span>
          </td>

          <td *ngIf="call.number">
            <a
              href="tel:{{ call.number }}"
              (click)="phoneCallService.createCalling(call.number)"
              >{{ call.number }}</a
            >
          </td>
          <td *ngIf="!call.number">Unbekannt</td>

          <td *ngIf="call.phone_caller_name">
            <a
              *ngIf="call.phone_caller_type == 'multiplier'"
              routerLink="/multipliers/{{ call.phone_caller_id }}"
            >
              {{ call.phone_caller_name }}
            </a>

            <a
              *ngIf="call.phone_caller_type == 'caregiver'"
              routerLink="/caregivers/{{ call.phone_caller_id }}"
            >
              {{ call.phone_caller_name }}
            </a>

            <small *ngIf="call.phone_caller_type == 'caregiver' && call.patient">
              <br>
              Kunde:
              <a routerLink="/patients/{{ call.patient_id }}">
                 {{ call.patient.full_name }}
              </a>
            </small>

            <a
              *ngIf="call.phone_caller_type == 'customer'"
              routerLink="/patients/{{ call.patient_id }}"
            >
              {{ call.phone_caller_name }}
            </a>

            <small *ngIf="call.phone_caller_type == 'customer' && call.caregiver">
              <br>
              BK:
              <a routerLink="/caregivers/{{ call.caregiver_id }}">
                {{ call.caregiver.full_name }}
              </a>
            </small>
          </td>
          <td *ngIf="!call.phone_caller_name">Unbekannt</td>

          <td>{{ call.created_at | date: "dd.MM.y, H:mm" }}</td>
          <td>
            <i
              class="pi pi-reply"
              style="font-size: 13px"
              pTooltip="Weiterleitung"
              *ngIf="call.is_redirect"
            ></i>
            <i
              class="pi pi-times"
              style="font-size: 13px"
              pTooltip="Nicht erreicht"
              *ngIf="call.not_reached"
            ></i>
          </td>
          <td>
            <i
              tooltipPosition="left"
              [pTooltip]="call.history_appointment_transfer.manual_title"
              class="pi pi-calendar color-blue"
              style="font-size: 14px"
              *ngIf="call.history_appointment_transfer"
            ></i>
          </td>
          <td>
            <i
              tooltipPosition="left"
              [pTooltip]="call.comment"
              style="font-size: 14px"
              class="pi pi-info-circle color-gray"
              *ngIf="call.comment"
            ></i>
          </td>
          <td>{{ call.duration || "N/A" }}</td>
          <td>
            {{ call.user_system?.first_name }} {{ call.user_system?.last_name }}
          </td>
          <td>
            <i class="pi pi-pencil" (click)="openPhonecallEditModal(call)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
