import { InternEventTimeModel } from './intern-event-time.model'
import { InternEventReadModel } from './intern-event-read.model'

export class InternEventModel {
  id = 0
  title = ''
  time_type = 'FIXED'
  comment = ''
  time_per_slot = 0
  is_working_time: boolean | null = null
  lecturer_id = 0
  lecturer_type = ''
  from_time = ''
  to_time = ''
  is_extern_lecturer = false
  extern_lecturer_email = ''
  extern_lecturer = ''
  address_type = 'OFFICE'
  address = ''
  address_zipcode = ''
  address_city = ''
  full_event_name = ''
  address_street_house_number = ''
  date: Date | string | null = null
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null

  all_caregiver_names = ''

  lecturer: any
  read: InternEventReadModel | null = null
  time: InternEventTimeModel | null = null
  times: InternEventTimeModel[] = [new InternEventTimeModel()]
}
